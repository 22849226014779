import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import store from "./redux/store"; // Asegúrate de que esta ruta sea correcta
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import keycloak from "./keycloak"; // Importa la configuración de Keycloak

const container = document.getElementById("root");
const root = createRoot(container);

// Función para manejar el almacenamiento
const handleStorage = async () => {
  try {
    if (document.hasStorageAccess) {
      const hasAccess = await document.hasStorageAccess();
      if (!hasAccess) {
        // Solo intentar solicitar acceso cuando el usuario interactúe
        document.addEventListener('click', async () => {
          try {
            await document.requestStorageAccess();
          } catch (error) {
            console.error('Error requesting storage access:', error);
          }
        }, { once: true });
      }
    }
  } catch (error) {
    console.error('Storage access error:', error);
  }
};

// Configurar el intervalo para verificar el token periódicamente si el usuario está autenticado
const setupTokenRefresh = () => {
  if (keycloak.authenticated) {
    return setInterval(() => {
      if (keycloak.isTokenExpired()) {
        keycloak.updateToken(30).catch(() => {
          console.error("Error al actualizar el token en el intervalo");
          // No redirigimos automáticamente, solo registramos el error
        });
      }
    }, 30000); // Verificar cada 30 segundos
  }
  return null;
};

// Inicializa Keycloak con onLoad: "check-sso" para verificar la sesión sin redirigir
keycloak
  .init({ onLoad: "check-sso", silentCheckSsoRedirectUri: window.location.origin + "/silent-check-sso.html" })
  .then((authenticated) => {
    if (authenticated) {
      //console.log("Usuario autenticado en la inicialización");
      // Configurar el intervalo para refrescar el token
      const tokenCheckInterval = setupTokenRefresh();
      
      // Limpiar el intervalo cuando la ventana se cierre
      window.addEventListener('beforeunload', () => {
        if (tokenCheckInterval) clearInterval(tokenCheckInterval);
      });
    } else {
      //console.log("Usuario no autenticado en la inicialización, permitiendo navegación anónima");
      // No redirigimos al login, permitimos la navegación anónima
    }
    
    handleStorage();
    root.render(
      <React.StrictMode>
        <Provider store={store}>
          <App />
        </Provider>
      </React.StrictMode>
    );
  })
  .catch((error) => {
    console.error("Error al inicializar Keycloak", error);
    // En caso de error, seguimos renderizando la aplicación sin autenticación
    handleStorage();
    root.render(
      <React.StrictMode>
        <Provider store={store}>
          <App />
        </Provider>
      </React.StrictMode>
    );
  });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
