import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_COD_URL as BASE_URL } from '../config/api';

export const fetchLocalitiesByDepartment = createAsyncThunk(
  'localities/fetchByDepartment',
  async (departmentId) => {
    try {
      const response = await axios.get(`${BASE_URL}/localidades/${departmentId}`);
      return {
        departmentId: parseInt(departmentId),
        localities: response.data
      };
    } catch (error) {
      console.error('Error fetching localities:', error);
      throw error;
    }
  }
);

const initialState = {
  localitiesByDepartment: {},
  status: 'idle',
  error: null
};

const localitiesSlice = createSlice({
  name: 'localities',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchLocalitiesByDepartment.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchLocalitiesByDepartment.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.localitiesByDepartment[action.payload.departmentId] = action.payload.localities;
      })
      .addCase(fetchLocalitiesByDepartment.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default localitiesSlice.reducer;
