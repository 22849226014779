import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

const Header = styled.div`
  background: white;
  padding: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 60px;
  left: 0;
  right: 0;
  z-index: 1500;
`;

const HeaderContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const BackButton = styled.button`
  background: none;
  border: none;
  color: #0b3357;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  
  &:hover {
    color: #1557a0;
  }
  
  &::before {
    content: '←';
    margin-right: 0.5rem;
  }
`;

const Title = styled.h1`
  font-size: 1.25rem;
  color: #0b3357;
  margin: 0;
  font-weight: 500;
`;

const PageHeader = ({ title, children }) => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <Header>
      <HeaderContent>
        <BackButton onClick={handleBack} />
        <Title>{title}</Title>
        {children}
      </HeaderContent>
    </Header>
  );
};

export default PageHeader;
