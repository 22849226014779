import * as Yup from 'yup';

export const step2Schema = Yup.object().shape({
  mediosDifusion: Yup.array()
    .min(1, 'Selecciona al menos un medio de difusión')
    .required('Los medios de difusión son requeridos'),
  mediosOtro: Yup.string()
    .when(['mediosDifusion'], {
      is: (mediosDifusion) => {
        // Buscar el ID correspondiente a "Otros" en el array de mediosDifusion
        return Array.isArray(mediosDifusion) && mediosDifusion.includes(9);
      },
      then: () => Yup.string()
        .required('Por favor, especifica el otro medio de difusión')
        .max(100, 'La descripción no debe exceder los 100 caracteres'),
      otherwise: () => Yup.string().nullable()
    })
});
