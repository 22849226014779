import React, { useCallback, memo, useEffect } from "react";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import FormError from "./common/FormError";
import { fetchRubros } from "../redux/rubrosSlice";

const AUDIENCE_OPTIONS = [
  { value: "familias", label: "Público en general", icon: "👨‍👩‍👧‍👦", description: "Personas sin segmentación específica" },
  { value: "empresas", label: "Empresas", icon: "🏢", description: "Negocios y organizaciones" },
  { value: "otro", label: "Otro", icon: "💡", description: "Especifica otro público objetivo" }
];

const Step1 = ({ formData, onChange, errors = {} }) => {
  const dispatch = useDispatch();
  const { rubros, status: rubrosStatus } = useSelector((state) => state.rubros);

  useEffect(() => {
    if (rubrosStatus === 'idle') {
      dispatch(fetchRubros());
    }
    
    // Asegurarse de que audiencia siempre sea un array
    if (!Array.isArray(formData.audiencia)) {
      onChange("audiencia", []);
    }
  }, [dispatch, rubrosStatus, formData.audiencia, onChange]);

  const handleAudienceChange = useCallback((value, isChecked) => {
    let newAudiencia = Array.isArray(formData.audiencia) ? [...formData.audiencia] : [];
    
    if (isChecked) {
      if (!newAudiencia.includes(value)) {
        newAudiencia.push(value);
      }
    } else {
      newAudiencia = newAudiencia.filter(item => item !== value);
      if (value === "otro") {
        onChange("audienciaOtro", "");
      }
    }
    
    onChange("audiencia", newAudiencia);
  }, [formData.audiencia, onChange]);

  const handleCustomAudienceChange = useCallback((e) => {
    e.stopPropagation();
    onChange("audienciaOtro", e.target.value);
  }, [onChange]);

  const handleRubroChange = useCallback((e) => {
    const value = e.target.value;
    onChange("rubroId", value);
    
    if (value !== "otro") {
      onChange("rubro", e.target.options[e.target.selectedIndex].text);
    } else {
      onChange("rubro", "");
    }
  }, [onChange]);

  const handleCustomRubroChange = useCallback((e) => {
    onChange("rubro", e.target.value);
  }, [onChange]);

  const handleDescriptionChange = useCallback((e) => {
    onChange("anuncioPersigue", e.target.value);
  }, [onChange]);

  return (
    <div className="step-container">
      <div className="form-section">
        <h2 className="section-title text-center">¿Qué objetivo persigues?</h2>
        
        <div className="description-section">
          <FormError error={errors.anuncioPersigue} />
          <Form.Control
            as="textarea"
            rows={4}
            value={formData.anuncioPersigue || ""}
            onChange={handleDescriptionChange}
            placeholder="Describa los objetivos de la publicidad. Algunos ejemplos: · Reconocimiento de Marca · Incrementar Ventas · Fidelización de Clientes · Lanzamiento de un Nuevo Producto o Servicio · Diferenciación de la Competencia · Cambio de Imagen o Reposicionamiento · Aumento del Tráfico a la Web o Tienda Física"
            className={`custom-textarea placeholder-small ${errors.anuncioPersigue ? 'is-invalid' : ''}`}
            style={{ fontSize: '1rem' }}
          />
        </div>
      </div>

      <div className="form-section mb-4">
        <h2 className="section-title text-center mb-3">¿Cuál es el rubro de tu negocio?</h2>
        <div className="section-subtitle text-center mb-4">Selecciona la categoría que mejor describe tu negocio <span className="text-danger">*</span></div>
        <div className="mx-auto" style={{ maxWidth: '800px' }}>
          <FormError error={errors.rubroId} />
          <div className="form-group">
            <Form.Select
              value={formData.rubroId || ""}
              onChange={handleRubroChange}
              isInvalid={!!errors.rubroId || !!errors.rubro}
              className="form-control form-select"
              style={{
                fontSize: '1rem',
                padding: '12px 16px',
                height: 'auto',
                cursor: 'pointer'
              }}
            >
              <option value="">Selecciona el sector de tu negocio</option>
              {rubros.map((rubro) => (
                <option 
                  key={rubro.id} 
                  value={rubro.id}
                  style={{ padding: '8px' }}
                >
                  {rubro.nombre}
                </option>
              ))}
              <option value="otro">Otro rubro...</option>
            </Form.Select>
            {formData.rubroId === "otro" && (
              <div className="mt-3">
                <FormError error={errors.rubro} />
                <Form.Control
                  type="text"
                  value={formData.rubro || ""}
                  onChange={handleCustomRubroChange}
                  placeholder="Especifica el rubro de tu negocio"
                  className={`form-control ${errors.rubro ? 'is-invalid' : ''}`}
                  style={{
                    fontSize: '1rem',
                    padding: '12px 16px'
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="form-section mb-4">
        <h2 className="section-title text-center mb-3">¿A quién va dirigido?</h2>
        <div className="section-subtitle text-center mb-4">Selecciona el público objetivo de tu campaña <span className="text-danger">*</span></div>
        {errors.audiencia && <FormError error={errors.audiencia} className="text-center mb-3" />}
        
        <div className="audience-options mx-auto" style={{ maxWidth: '800px' }}>
          {AUDIENCE_OPTIONS.map((option) => (
            <div
              key={option.value}
              className={`audience-option ${
                formData.audiencia?.includes(option.value) ? 'active' : ''
              } ${errors.audiencia ? 'is-invalid-border' : ''}`}
            >
              <Form.Check
                type="checkbox"
                id={`audience-${option.value}`}
                checked={formData.audiencia?.includes(option.value)}
                onChange={(e) => handleAudienceChange(option.value, e.target.checked)}
                label={
                  <div className="option-content">
                    <div className="option-header">
                      {option.icon} {option.label}
                    </div>
                    <div className="option-description">
                      {option.description}
                    </div>
                  </div>
                }
              />
              {option.value === "otro" && formData.audiencia?.includes("otro") && (
                <div className="custom-input-wrapper">
                  <FormError error={errors.audienciaOtro} />
                  <Form.Control
                    type="text"
                    value={formData.audienciaOtro || ""}
                    onChange={handleCustomAudienceChange}
                    placeholder="Especifica el público objetivo"
                    className={`custom-input ${errors.audienciaOtro ? 'is-invalid' : ''}`}
                  />
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default memo(Step1);
