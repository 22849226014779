// src/redux/adRequestSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from '../config/api';
import keycloak from '../keycloak';

const transformFormData = (formData) => {
  const currentDate = new Date().toISOString();
  
  // Cambio la primer letra de formData.audiencia a mayúsculas
  formData.audiencia = formData.audiencia.map(audiencia => audiencia.charAt(0).toUpperCase() + audiencia.slice(1));

  // Verificar y obtener el ID del departamento
  //const departamentoId = formData.zonaGeografica?.departamentos?.[0];
  
  return {
    id: formData.id || null,
    descripcionCampania: formData.anuncioPersigue,
    publicoObjetivo: formData.audiencia.join(", ") + (formData.audienciaOtro ? `, ${formData.audienciaOtro.charAt(0).toUpperCase() + formData.audienciaOtro.slice(1)}` : ""),
    moneda: {
      id: formData.presupuesto.moneda === "USD" ? 1 : 2 // 2 para USD, 1 para UYU
    },
    presupuesto: parseFloat(formData.presupuesto.monto),
    mensual: formData.periodicidadPago === "mensual",
    cantidadCuotas: parseInt(formData.cantidadMeses) || 1,
    fechaCreacion: currentDate,
    fechaCierre: formData.fechaLimitePostulacion,
    estado: {
      id: 1
    },
    departamento: {
      id: 16
    },
    solicitudLocalidades: Object.entries(formData.zonaGeografica.localidades || {}).map(([id, data]) => ({
      localidad: { 
        id: id.startsWith('custom_') ? -1 : parseInt(id),
        nombre: id.startsWith('custom_') ? data.nombre : null
      },
      monto: parseFloat(data.monto) || 0
    })),
    tipoMedios: formData.mediosDifusion.map(id => ({
      id: id
    })),
    otroTipoMedio: formData.mediosOtro ? formData.mediosOtro.charAt(0).toUpperCase() + formData.mediosOtro.slice(1) : "",
    usuario: formData.usuario, //correo obtenido desde el usuario
    fechaPublicacion: formData.fechaInicioAnuncio || null,
    fechaFinPublicacion: formData.fechaFinAnuncio || null,
    rubro: formData.rubro,  // Usamos directamente el valor de rubro
    estadoMaterial: formData.tienePiezaPublicitaria,
    otroEstadoMaterial: formData.piezaPublicitariaOtro || null,
    tipoMaterial: formData.tipoMaterial || null,
    duracionMaterial: formData.duracionMaterial ? parseInt(formData.duracionMaterial) : null,
    descripcionProducto: formData.descripcionProducto || null
  };
};

export const submitAdRequest = createAsyncThunk(
  "adRequest/submitAdRequest",
  async ({ formData }, { rejectWithValue }) => {
    try {
      console.log('Data:', formData); // Para debug
      const transformedData = transformFormData(formData);
      console.log('Transformed data:', transformedData); // Para debug
      
      // Verificar si el usuario está autenticado
      if (!keycloak.authenticated) {
        //console.log("Usuario no autenticado, redirigiendo al login de Keycloak para enviar el formulario");
        keycloak.login();
        return rejectWithValue('No autenticado');
      }
      
      // Verificar si el token está expirado
      if (keycloak.isTokenExpired()) {
        try {
          // Intentar actualizar el token
          const refreshed = await keycloak.updateToken(30);
          // if (refreshed) {
          //   console.log("Token actualizado exitosamente en adRequestSlice");
          // } else {
          //   console.log("Token aún válido en adRequestSlice");
          // }
        } catch (error) {
          console.error("Error al actualizar el token en adRequestSlice, redirigiendo al login");
          keycloak.login();
          return rejectWithValue('Token expirado');
        }
      }
      
      // Configurar los headers con el token de autenticación de Keycloak
      const headers = {};
      
      if (keycloak.token) {
        headers['Authorization'] = `Bearer ${keycloak.token}`;
        //console.log('Token enviado en la petición de envío del formulario:', keycloak.token);
      } else {
        console.warn('Token no disponible para enviar el formulario, redirigiendo al login');
        keycloak.login();
        return rejectWithValue('Token no disponible');
      }
      
      // Enviar la solicitud de anuncio
      const response = await axios.post(
        `${BASE_URL}/anunciante/sep`,
        transformedData,
        { headers }
      );

      return response.data;
    } catch (error) {
      // Si el error es 401 o 403, redirigir al login
      if (error.response && (error.response.status === 401 || error.response.status === 403)) {
        //console.error('Error de autenticación:', error.response.status);
        keycloak.login();
      }
      
      //  console.error('Error al enviar la solicitud de anuncio:', error);
      return rejectWithValue(error.response?.data || 'Error al enviar la solicitud de anuncio');
    }
  }
);

const adRequestSlice = createSlice({
  name: "adRequest",
  initialState: {
    loading: false,
    data: null,
    error: null,
    customLocations: {} // Nuevo estado para localidades personalizadas
  },
  reducers: {
    addCustomLocation: (state, action) => {
      const { id, nombre } = action.payload;
      state.customLocations[id] = nombre;
    },
    clearCustomLocations: (state) => {
      state.customLocations = {};
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(submitAdRequest.pending, (state) => {
        state.loading = true;
      })
      .addCase(submitAdRequest.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
        state.error = null;
      })
      .addCase(submitAdRequest.rejected, (state, action) => {
        state.loading = false;
        state.data = null;
        state.error = action.error.message;
      });
  },
});

export const { addCustomLocation, clearCustomLocations } = adRequestSlice.actions;
export default adRequestSlice.reducer;
