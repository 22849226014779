import * as Yup from "yup";

export const step5Schema = Yup.object().shape({
  descripcionProducto: Yup.string()
    .required("Por favor, describe tu producto o servicio")
    .min(10, "La descripción debe tener al menos 10 caracteres")
    .max(500, "La descripción no debe exceder los 500 caracteres"),
  tienePiezaPublicitaria: Yup.string().required(
    "Por favor, indica si tienes material publicitario"
  ),
  tipoMaterial: Yup.string().when("tienePiezaPublicitaria", {
    is: "Si",
    then: () =>
      Yup.string().required(
        "Por favor, selecciona el tipo de material publicitario"
      ),
    otherwise: () => Yup.string().nullable(),
  }),
  duracionMaterial: Yup.string().when(
    ["tienePiezaPublicitaria", "tipoMaterial"],
    {
      is: (tienePiezaPublicitaria, tipoMaterial) =>
        tienePiezaPublicitaria === "Si" &&
        (tipoMaterial === "video" || tipoMaterial === "audio"),
      then: () =>
        Yup.string().required("Por favor, selecciona la duración del material"),
      otherwise: () => Yup.string().nullable(),
    }
  ),
  piezaPublicitariaOtro: Yup.string().when("tienePiezaPublicitaria", {
    is: "Otro",
    then: () =>
      Yup.string()
        .required("Por favor, especifica tu situación")
        .min(10, "La descripción debe tener al menos 10 caracteres")
        .max(200, "La descripción no debe exceder los 200 caracteres"),
    otherwise: () => Yup.string().nullable(),
  }),
  fechaLimitePostulacion: Yup.mixed()
    .transform((value) => {
      if (!value || value === "") return undefined;
      const date = new Date(value);
      return isNaN(date) ? undefined : date;
    })
    .required("Debes seleccionar una fecha límite de postulación")
    .test("is-date", "La fecha ingresada no es válida", (value) => {
      if (!value) return false;
      return value instanceof Date && !isNaN(value);
    })
    .test(
      "is-future",
      "La fecha límite debe ser posterior a 5 días a partir de hoy",
      (value) => {
        if (!value) return false;
        const minDate = new Date();
        minDate.setDate(minDate.getDate() + 5);
        minDate.setHours(0, 0, 0, 0);
        return value >= minDate;
      }
    ),
  fechaInicioAnuncio: Yup.mixed()
    .transform((value) => {
      if (!value || value === "") return null;
      const date = new Date(value);
      return isNaN(date) ? undefined : date;
    })
    .nullable(),
  fechaFinAnuncio: Yup.mixed()
    .transform((value) => {
      if (!value || value === "") return null;
      const date = new Date(value);
      return isNaN(date) ? undefined : date;
    })
    .nullable()
    .test(
      "is-valid-range",
      "La fecha de fin debe ser posterior a la fecha de inicio",
      function (value) {
        const { fechaInicioAnuncio } = this.parent;

        if (!fechaInicioAnuncio || !value) {
          return true;
        }

        return value > fechaInicioAnuncio;
      }
    ),
});
