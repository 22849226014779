import * as Yup from 'yup';

export const step4Schema = Yup.object().shape({
  presupuesto: Yup.object(),
  zonaGeografica: Yup.object().test({
    name: 'total',
    message: 'El total asignado debe ser igual al presupuesto total',
    test: function(value) {
      if (!value?.localidades || Object.keys(value.localidades).length === 0) return true;
      
      const total = Object.values(value.localidades)
        .reduce((sum, localidad) => sum + parseFloat(localidad.monto || 0), 0);
      
      const presupuestoTotal = parseFloat(this.parent.presupuesto.monto);
      
      // Permitir una diferencia de hasta 0.1 para manejar redondeos
      return Math.abs(total - presupuestoTotal) <= 0.1;
    }
  })
});
