import React from 'react';
import './FormError.css';

const FormError = ({ error, className = '' }) => {
  if (!error) return null;
  
  return (
    <div className={`form-error ${className}`}>
      {error}
    </div>
  );
};

export default FormError;
