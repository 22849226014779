import React from 'react';
import keycloak from '../keycloak';

const LoginButton = () => {
  const handleLogin = () => {
    keycloak.login();
  };

  return (
    <button onClick={handleLogin} style={loginButtonStyle}>Iniciar Sesión</button>
  );
};

const loginButtonStyle = {
  backgroundColor: "#1b74c5",
  color: "white",
  border: "none",
  padding: "10px 20px",
  fontSize: "1em",
  cursor: "pointer",
  borderRadius: "5px",
  transition: "background-color 0.3s, transform 0.3s",
};

loginButtonStyle.hover = {
  backgroundColor: "#155a9e",
  transform: "scale(1.05)",
};

export default LoginButton;
