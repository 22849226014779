// src/redux/store.js
import { configureStore } from '@reduxjs/toolkit';
import adRequestReducer from './adRequestSlice';
import departmentsReducer from './departmentsSlice'; 
import localitiesReducer from './localitiesSlice'; 
import userAdsReducer from './userAdsSlice';
import adDetailsReducer from './adDetailsSlice';
import mediosReducer from './mediosSlice';
import rubrosReducer from './rubrosSlice';

const store = configureStore({
  reducer: {
    adRequest: adRequestReducer,
    departments: departmentsReducer, 
    localities: localitiesReducer, 
    userAds: userAdsReducer,
    adDetails: adDetailsReducer,
    medios: mediosReducer,
    rubros: rubrosReducer,
  },
});

export default store;
