import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { fetchAdDetails } from "../redux/adDetailsSlice";
import { formatDate, formatMonto } from "../utils/formats";
import PageHeader from "../components/PageHeader";

const PageContainer = styled.div`
  min-height: 100vh;
  background: #f5f7fa;
  padding-top: 80px;

  @media (min-width: 768px) {
    padding-top: 100px;
  }
`;

const ContentContainer = styled.div`
  max-width: 1200px;
  margin: 1.5rem auto 2rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    margin: 2rem auto 2rem;
    padding: 2rem;
  }
`;

const HeaderSection = styled.div`
  margin-bottom: 2rem;
  display: flex;
  justify-content: flex-end;
`;

const StepsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 2rem;
  margin-top: 2rem;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }

  & > *:first-child {
    grid-column: 1 / -1;
  }
`;

const StepCard = styled.div`
  background: white;
  border-radius: 16px;
  padding: 2rem;
  box-shadow: 0 4px 6px rgba(11, 51, 87, 0.04);
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-4px);
    box-shadow: 0 12px 24px rgba(11, 51, 87, 0.08);
  }

  &.featured {
    background: white;
    padding: 0;
    border: 1px solid rgba(11, 51, 87, 0.1);

    &:hover {
      border-color: #0b3357;
    }
  }

  @media (max-width: 768px) {
    padding: 1.5rem;
  }
`;

const AnnouncementContent = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  padding: 1.5rem;

  @media (min-width: 768px) {
    padding: 2.5rem;
  }

  .header {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 1.5rem;

    @media (min-width: 768px) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }

  h3 {
    color: #0b3357;
    font-size: 1.25rem;
    font-weight: 600;
    margin: 0;

    @media (min-width: 768px) {
      font-size: 1.5rem;
    }
  }

  .description {
    font-size: 1.1rem;
    line-height: 1.7;
    color: #2c3e50;
    margin-bottom: 2rem;

    @media (min-width: 768px) {
      font-size: 1.2rem;
      line-height: 1.8;
    }
  }

  .details-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1.5rem;

    @media (min-width: 640px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (min-width: 1024px) {
      grid-template-columns: repeat(3, 1fr);
      gap: 2rem;
    }
  }

  .detail-section {
    h4 {
      color: #0b3357;
      font-size: 1rem;
      font-weight: 600;
      margin: 0 0 0.75rem 0;
      padding-bottom: 0.5rem;
      border-bottom: 1px solid rgba(11, 51, 87, 0.1);

      @media (min-width: 768px) {
        font-size: 1.1rem;
        margin: 0 0 1rem 0;
      }
    }

    .content {
      color: #2c3e50;
      line-height: 1.6;
      font-size: 1rem;

      @media (min-width: 768px) {
        line-height: 1.7;
        font-size: 1.1rem;
      }
    }

    .tags {
      display: flex;
      flex-wrap: wrap;
      gap: 0.5rem;
    }

    .tag {
      background: rgba(11, 51, 87, 0.05);
      padding: 0.375rem 0.75rem;
      border-radius: 20px;
      font-size: 0.875rem;
      color: #0b3357;

      @media (min-width: 768px) {
        padding: 0.4rem 0.8rem;
        font-size: 0.9rem;
      }
    }

    .locations {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    }

    .location-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.5rem;
      background: rgba(11, 51, 87, 0.02);
      border-radius: 8px;
      font-size: 0.9rem;

      @media (min-width: 768px) {
        font-size: 1rem;
      }

      .location-name {
        font-weight: 500;
      }

      .location-amount {
        color: #0b3357;
        font-weight: 600;
      }
    }
  }
`;

const AnnouncementHeader = styled.div`
  background: #0b3357;
  padding: 2rem;
  color: white;
  position: relative;
  overflow: hidden;
  border-radius: 16px 16px 0 0;

  &::before {
    content: "?";
    position: absolute;
    right: -20px;
    top: -30px;
    font-size: 180px;
    font-weight: bold;
    opacity: 0.1;
    color: white;
  }

  h3 {
    font-size: 1.75rem;
    margin: 0;
    font-weight: 600;
    position: relative;
  }
`;

const StepHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1.5rem;

  .step-number {
    background: rgba(11, 51, 87, 0.05);
    color: #0b3357;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 1.1rem;
  }

  h3 {
    margin: 0;
    font-size: 1.25rem;
    color: #0b3357;
    font-weight: 600;
  }
`;

const AdStatus = styled.div`
  display: inline-flex;
  align-items: center;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  font-size: 0.875rem;
  font-weight: 600;
  gap: 0.375rem;

  @media (min-width: 768px) {
    padding: 0.625rem 1.25rem;
    font-size: 0.9rem;
  }

  &::before {
    content: "";
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: currentColor;

    @media (min-width: 768px) {
      width: 8px;
      height: 8px;
    }
  }

  ${(props) => {
    const statusStyles = {
      abierta: {
        color: "#4CAF50",
        background: "rgba(76, 175, 80, 0.15)",
      },
      "en proceso": {
        color: "#FF9800",
        background: "rgba(255, 152, 0, 0.15)",
      },
      completada: {
        color: "#2196F3",
        background: "rgba(33, 150, 243, 0.15)",
      },
      cancelada: {
        color: "#F44336",
        background: "rgba(244, 67, 54, 0.15)",
      },
    };

    const style = statusStyles[props.status?.toLowerCase()] || {
      color: "#757575",
      background: "rgba(117, 117, 117, 0.15)",
    };

    return `
      color: ${style.color};
      background: ${style.background};
    `;
  }}
`;

const DetailList = styled.dl`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 1rem 2rem;
  margin: 0;

  dt {
    color: ${(props) => (props.light ? "rgba(255, 255, 255, 0.8)" : "#666")};
    font-weight: 500;
  }

  dd {
    margin: 0;
    color: ${(props) => (props.light ? "white" : "#0b3357")};
    font-weight: 500;
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 0.5rem;

    dt {
      color: ${(props) => (props.light ? "rgba(255, 255, 255, 0.7)" : "#888")};
      font-size: 0.875rem;
      margin-top: 1rem;

      &:first-child {
        margin-top: 0;
      }
    }
  }
`;

const ListItem = styled.li`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.75rem;
  color: #0b3357;
  font-weight: 500;

  &::before {
    content: "•";
    color: #0b3357;
    font-size: 1.5rem;
    line-height: 1;
  }
`;

const MATERIAL_OPTIONS = {
  YA_TENGO_EL_MATERIAL: "Ya tengo el material",
  NO_TENGO_EL_MATERIAL: "No tengo el material",
  OTRA_SITUACION: "Otra situación",
};

const AdDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { ad, status, error } = useSelector((state) => state.adDetails);
  const [activeStep, setActiveStep] = useState(1);

  useEffect(() => {
    dispatch(fetchAdDetails(id));
  }, [dispatch, id]);


  if (status === "loading") {
    return (
      <PageContainer>
        <ContentContainer>
          <div className="text-center p-5">
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Cargando...</span>
            </div>
          </div>
        </ContentContainer>
      </PageContainer>
    );
  }

  if (status === "failed") {
    return (
      <PageContainer>
        <ContentContainer>
          <div className="alert alert-danger" role="alert">
            Error al cargar los detalles: {error}
          </div>
        </ContentContainer>
      </PageContainer>
    );
  }

  if (!ad) {
    return (
      <PageContainer>
        <ContentContainer>
          <div className="alert alert-info" role="alert">
            Anuncio no encontrado
          </div>
        </ContentContainer>
      </PageContainer>
    );
  }

  return (
    <PageContainer>
      <PageHeader title="Detalles del Anuncio" />
      <ContentContainer>
        <StepsGrid>
          <StepCard className="featured">
            <AnnouncementContent>
              <div className="header">
                <h3>Objetivo del Anuncio</h3>
                <AdStatus status={ad.estado.nombre}>
                  {ad.estado.nombre}
                </AdStatus>
              </div>
              <div className="description">{ad.descripcionCampania}</div>

              <div className="detail-section" style={{ marginBottom: "2rem" }}>
                <h4>Descripción del Producto/Servicio</h4>
                <div className="content">
                  {ad.descripcionProducto || "No especificado"}
                </div>
              </div>

              <div className="details-grid">
                <div className="detail-section">
                  <h4>Público Objetivo</h4>
                  <div className="tags">
                    {ad.publicoObjetivo.split(",").map((target, index) => (
                      <span key={index} className="tag">
                        {target.trim()}
                      </span>
                    ))}
                  </div>
                </div>

                <div className="detail-section">
                  <h4>Rubro del Negocio</h4>
                  <div className="content">
                    {typeof ad.rubro === "object"
                      ? ad.rubro?.nombre
                      : ad.rubro || "No especificado"}
                  </div>
                </div>

                <div className="detail-section">
                  <h4>Presupuesto</h4>
                  <div className="content">
                    {ad.moneda.simbolo} {ad.presupuesto.toFixed(2)}{" "}
                    {ad.moneda.codigo}
                    <br />
                    {ad.mensual ? "Renovación Mensual" : "Por única vez"}
                    {ad.cantidadCuotas > 1 && ` - ${ad.cantidadCuotas} cuotas`}
                  </div>
                </div>

                <div className="detail-section">
                  <h4>Ubicación</h4>
                  <div className="content">
                    <div>Departamento: {ad.departamento.nombre}</div>
                    <div className="locations">
                      {ad.solicitudLocalidades.map((loc) => (
                        <div key={loc.id} className="location-item">
                          <span className="location-name">
                            {loc.localidad.nombre}
                          </span>
                          <span className="location-amount">
                            {ad.moneda.simbolo} {loc.monto.toFixed(2)}
                          </span>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                <div className="detail-section">
                  <h4>Medios de Difusión</h4>
                  <div className="tags">
                    {ad.tipoMedios.map((medio) => (
                      <span
                        key={medio.id}
                        className="tag"
                        title={medio.detalle}
                      >
                        {medio.descripcion}
                      </span>
                    ))}
                    {ad.otroTipoMedio && (
                      <span className="tag">{ad.otroTipoMedio}</span>
                    )}
                  </div>
                </div>

                <div className="detail-section">
                  <h4>Material Publicitario</h4>
                  <div className="content">
                    <div>
                      Estado:{" "}
                      {MATERIAL_OPTIONS[ad.estadoMaterial] || ad.estadoMaterial}
                    </div>
                    {ad.otroEstadoMaterial && (
                      <div>Detalle: {ad.otroEstadoMaterial}</div>
                    )}
                    {ad.tipoMaterial && (
                      <div>
                        Tipo:{" "}
                        {ad.tipoMaterial.charAt(0).toUpperCase() +
                          ad.tipoMaterial.slice(1)}
                      </div>
                    )}
                    {ad.duracionMaterial && (
                      <div>Duración: {ad.duracionMaterial} segundos</div>
                    )}
                  </div>
                </div>

                <div className="detail-section">
                  <h4>Fechas</h4>
                  <div className="content">
                    <div>
                      Creación:{" "}
                      {new Date(ad.fechaCreacion).toLocaleDateString()}
                    </div>
                    <div>
                      Cierre: {new Date(ad.fechaCierre).toLocaleDateString()}
                    </div>
                    {ad.fechaPublicacion && (
                      <div>
                        Publicación:{" "}
                        {new Date(ad.fechaPublicacion).toLocaleDateString()}
                      </div>
                    )}
                    {ad.fechaFinPublicacion && (
                      <div>
                        Fin Publicación:{" "}
                        {new Date(ad.fechaFinPublicacion).toLocaleDateString()}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </AnnouncementContent>
          </StepCard>
        </StepsGrid>
      </ContentContainer>
    </PageContainer>
  );
};

export default AdDetails;
