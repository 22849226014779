import Keycloak from "keycloak-js";

const keycloak = new Keycloak({
  url: process.env.REACT_APP_KEYCLOAK_URL,
  realm: "publicity",
  clientId: "publicity_app",
  redirectUri: window.location.origin + "/mis-anuncios"
});

export default keycloak;
