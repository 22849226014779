// src/components/AnimatedText.js
import React, { useState, useEffect } from "react";

const AnimatedText = ({ texts, showWelcome = true }) => {
  const [currentTextIndex, setCurrentTextIndex] = useState(0);
  const [fadeIn, setFadeIn] = useState(true);

  useEffect(() => {
    // Si showWelcome es false, no iniciar la animación
    if (!showWelcome) return;

    const interval = setInterval(() => {
      setFadeIn(false);
      setTimeout(() => {
        setCurrentTextIndex((prevIndex) => (prevIndex + 1) % texts.length);
        setFadeIn(true);
      }, 500); // Tiempo de transición de fade out
    }, 3000); // Cambia cada 3 segundos

    return () => {
      clearInterval(interval);
    };
  }, [texts.length, showWelcome]);

  const currentText = texts[currentTextIndex];

  return (
    <div className={`animated-text ${fadeIn ? "fade-in" : "fade-out"}`}>
      <h2 style={{ fontSize: "1.5em", color: currentText.color }}>
        {currentText.text}
      </h2>
    </div>
  );
};

export default AnimatedText;
