import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL } from '../config/api';
import keycloak from '../keycloak';

export const fetchAdDetails = createAsyncThunk(
  'adDetails/fetchAdDetails',
  async (id, { rejectWithValue }) => {
    try {
      // Verificar si el usuario está autenticado - esta es una página protegida
      if (!keycloak.authenticated) {
        //console.log("Usuario no autenticado, redirigiendo al login de Keycloak para ver detalles del anuncio");
        keycloak.login();
        return rejectWithValue('No autenticado');
      }
      
      // Verificar si el token está expirado
      if (keycloak.isTokenExpired()) {
        try {
          // Intentar actualizar el token
          const refreshed = await keycloak.updateToken(30);
          // if (refreshed) {
          //   console.log("Token actualizado exitosamente en adDetailsSlice");
          // } else {
          //   console.log("Token aún válido en adDetailsSlice");
          // }
        } catch (error) {
          console.error("Error al actualizar el token en adDetailsSlice, redirigiendo al login");
          keycloak.login();
          return rejectWithValue('Token expirado');
        }
      }
      
      // Configurar los headers con el token de autenticación de Keycloak
      const headers = {};
      
      if (keycloak.token) {
        headers['Authorization'] = `Bearer ${keycloak.token}`;
        // console.log('Token enviado en la petición de detalles del anuncio:', keycloak.token);
      } else {
        console.warn('Token no disponible para obtener detalles del anuncio, redirigiendo al login');
        keycloak.login();
        return rejectWithValue('Token no disponible');
      }
      
      const response = await axios.get(`${BASE_URL}/anunciante/sep/${id}`, { headers });
      return response.data;
    } catch (error) {
      // Si el error es 401 o 403, redirigir al login
      if (error.response && (error.response.status === 401 || error.response.status === 403)) {
        console.error('Error de autenticación:', error.response.status);
        keycloak.login();
      }
      
      console.error('Error fetching ad details:', error);
      return rejectWithValue(error.response?.data || 'Error al cargar los detalles del anuncio');
    }
  }
);

const adDetailsSlice = createSlice({
  name: 'adDetails',
  initialState: {
    ad: null,
    status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAdDetails.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(fetchAdDetails.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.ad = action.payload;
        state.error = null;
      })
      .addCase(fetchAdDetails.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

export default adDetailsSlice.reducer;
