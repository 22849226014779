import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { formatDate } from '../utils/formats';

const ProfileSection = styled.div`
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  transition: transform 0.2s;
  
  &:hover {
    transform: translateY(-2px);
  }
`;

const SectionTitle = styled.h2`
  font-size: 1.2rem;
  color: #0b3357;
  margin-bottom: 1.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 2px solid #f0f2f5;
`;

const StatsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  margin-bottom: 1rem;
  text-align: center;
`;

const StatCard = styled.div`
  background: #f8fafc;
  padding: 0.5rem;
  border-radius: 8px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const StatValue = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
  color: #0b3357;
  margin-bottom: 0.5rem;
  text-align: center;
`;

const StatLabel = styled.div`
  font-size: 0.875rem;
  color: #64748b;
  text-align: center;
`;

const LastLoginInfo = styled.div`
  font-size: 0.875rem;
  color: #64748b;
  text-align: center;
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px solid #f0f2f5;
`;

const ActivitySummary = () => {
  const { ads, status } = useSelector(state => state.userAds);

  return (
    <ProfileSection>
      <SectionTitle>Resumen de Actividad</SectionTitle>
      <StatsGrid>
        <StatCard>
          <StatValue>{status === 'loading' ? '...' : ads.filter(ad => ad.estado.nombre === 'Abierta').length}</StatValue>
          <StatLabel>Anuncios abiertos</StatLabel>
        </StatCard>
        <StatCard>
          <StatValue>{status === 'loading' ? '...' : ads.length}</StatValue>
          <StatLabel>Anuncios totales</StatLabel>
        </StatCard>
        <StatCard>
          <StatValue>{status === 'loading' ? '...' : 1}</StatValue>
          <StatLabel>Departamentos</StatLabel>
        </StatCard>
        <StatCard>
          <StatValue>{status === 'loading' ? '...' : [...new Set(ads.flatMap(ad => ad.solicitudLocalidades.map(loc => loc.localidad.nombre)))].length}</StatValue>
          <StatLabel>Localidades</StatLabel>
        </StatCard>
      </StatsGrid>
      <LastLoginInfo>
        Último acceso: {formatDate(new Date().toISOString())}
      </LastLoginInfo>
    </ProfileSection>
  );
};

export default ActivitySummary;
