import React, { useState, useEffect, useCallback, useMemo, memo } from "react";
import { Form } from "react-bootstrap";
import { useSelector, useDispatch } from 'react-redux';
import { fetchDepartments } from '../redux/departmentsSlice';
import { fetchLocalitiesByDepartment } from '../redux/localitiesSlice';
import { addCustomLocation } from '../redux/adRequestSlice';
import FormError from "./common/FormError";

const Step4 = memo(({ formData, onChange, onZonaGeograficaChange, errors = {} }) => {
  const dispatch = useDispatch();

  // Estados
  const [presupuestoRestante, setPresupuestoRestante] = useState(formData.presupuesto?.monto || 0);
  const [porcentajeAsignado, setPorcentajeAsignado] = useState(0);
  const [mostrarLocalidades, setMostrarLocalidades] = useState(false);
  const [departamentoSeleccionado, setDepartamentoSeleccionado] = useState('16'); // ID de SAN JOSE
  const [localidadesSeleccionadas, setLocalidadesSeleccionadas] = useState([]);
  const [showLocationSelector, setShowLocationSelector] = useState(false);
  const [mostrarInputOtraLocalidad, setMostrarInputOtraLocalidad] = useState(false);
  const [nombreOtraLocalidad, setNombreOtraLocalidad] = useState('');

  // Redux selectors
  const { departments, status: deptStatus } = useSelector((state) => state.departments);
  const { localitiesByDepartment } = useSelector((state) => state.localities);
  const customLocations = useSelector((state) => state.adRequest.customLocations);

  // Funciones auxiliares
  const getLocalidadNombre = useCallback((localidadId) => {
    if (typeof localidadId === 'string' && localidadId.startsWith('custom_')) {
      return customLocations[localidadId] || localidadId;
    }
    if (departamentoSeleccionado && localitiesByDepartment[departamentoSeleccionado]) {
      const localidad = localitiesByDepartment[departamentoSeleccionado].find(
        loc => loc.id.toString() === localidadId.toString()
      );
      return localidad ? localidad.nombre || localidad.name : localidadId;
    }
    return localidadId;
  }, [departamentoSeleccionado, localitiesByDepartment, customLocations]);

  const getLocalidadesDisponibles = useCallback(() => {
    if (!departamentoSeleccionado || !localitiesByDepartment[departamentoSeleccionado]) {
      return [];
    }
    
    const localidadesActuales = Object.keys(formData.zonaGeografica?.localidades || {});
    return localitiesByDepartment[departamentoSeleccionado].filter(
      loc => !localidadesActuales.includes(loc.id.toString())
    );
  }, [departamentoSeleccionado, localitiesByDepartment, formData.zonaGeografica?.localidades]);

  // Handlers
  const handleDepartamentoChange = useCallback((e) => {
    const selectedDeptId = e.target.value;
    setDepartamentoSeleccionado(selectedDeptId);
    setLocalidadesSeleccionadas([]);
    
    // Asegurarnos de que el departamento siempre esté en el array
    onZonaGeograficaChange('departamentos', selectedDeptId ? [parseInt(selectedDeptId)] : []);
    // Limpiar las localidades cuando se cambia de departamento
    onZonaGeograficaChange('localidades', {});
  }, [onZonaGeograficaChange]);

  const handleMostrarLocalidadesChange = useCallback((checked) => {
    setMostrarLocalidades(checked);
    if (!checked) {
      onZonaGeograficaChange('localidades', {});
    }
  }, [onZonaGeograficaChange]);

  const handleLocalidadPresupuestoChange = useCallback((localidadId, monto) => {
    // Validar formato de número con máximo 2 decimales
    const regex = /^\d*\.?\d{0,2}$/;
    if (monto !== '' && !regex.test(monto)) {
      return;
    }

    const montoNum = monto === '' ? 0 : parseFloat(monto) || 0;
    const localidadIdStr = localidadId.toString();
    const presupuestoTotal = formData.presupuesto?.monto || 0;

    const otherLocalidadesTotal = Object.entries(formData.zonaGeografica?.localidades || {})
      .reduce((sum, [key, value]) => 
        key !== localidadIdStr ? sum + (parseFloat(value.monto) || 0) : sum, 
        0
      );

    // Permitimos una diferencia de redondeo más flexible (0.1)
    if (montoNum + otherLocalidadesTotal <= presupuestoTotal + 0.1) {
      const newLocalidades = { ...(formData.zonaGeografica?.localidades || {}) };
      newLocalidades[localidadIdStr] = { ...newLocalidades[localidadIdStr], monto };
      onZonaGeograficaChange('localidades', newLocalidades);
    }
  }, [formData.zonaGeografica?.localidades, formData.presupuesto?.monto, onZonaGeograficaChange]);

  const agregarLocalidad = useCallback((localidad) => {
    if (localidad === "otra") {
      setMostrarInputOtraLocalidad(true);
      return;
    }

    if (localidad) {
      const localidadId = parseInt(localidad);
      const newLocalidades = { ...(formData.zonaGeografica?.localidades || {}) };
      newLocalidades[localidadId.toString()] = { nombre: getLocalidadNombre(localidadId), monto: 0 };
      onZonaGeograficaChange('localidades', newLocalidades);
      setShowLocationSelector(false);
    }
  }, [formData.zonaGeografica?.localidades, onZonaGeograficaChange, getLocalidadNombre]);

  const agregarOtraLocalidad = useCallback(() => {
    if (nombreOtraLocalidad.trim()) {
      const localidadId = `custom_${Date.now()}`;
      
      // Dispatch para agregar la localidad personalizada al estado global
      dispatch(addCustomLocation({ id: localidadId, nombre: nombreOtraLocalidad }));
      
      const newLocalidades = { ...(formData.zonaGeografica?.localidades || {}) };
      newLocalidades[localidadId] = {
        nombre: nombreOtraLocalidad,
        monto: 0
      };
      onZonaGeograficaChange('localidades', newLocalidades);
      
      setMostrarInputOtraLocalidad(false);
      setNombreOtraLocalidad('');
    }
  }, [nombreOtraLocalidad, formData.zonaGeografica?.localidades, onZonaGeograficaChange, dispatch]);

  const distribuirEquitativamente = useCallback(() => {
    const localidades = formData.zonaGeografica?.localidades || {};
    const cantidadLocalidades = Object.keys(localidades).length;
    
    if (cantidadLocalidades > 0 && formData.presupuesto?.monto > 0) {
      const montoEquitativo = (formData.presupuesto?.monto / cantidadLocalidades).toFixed(2);
      const newLocalidades = Object.keys(localidades).reduce((acc, localidadId) => {
        acc[localidadId] = { ...acc[localidadId], monto: montoEquitativo };
        return acc;
      }, {});
      
      onZonaGeograficaChange('localidades', newLocalidades);
    }
  }, [formData.zonaGeografica?.localidades, formData.presupuesto?.monto, onZonaGeograficaChange]);

  // Effects
  useEffect(() => {
    if (deptStatus === 'idle') {
      dispatch(fetchDepartments());
    }
  }, [deptStatus, dispatch]);

  useEffect(() => {
    if (departamentoSeleccionado && !localitiesByDepartment[departamentoSeleccionado]) {
      dispatch(fetchLocalitiesByDepartment(parseInt(departamentoSeleccionado)));
    }
  }, [departamentoSeleccionado, dispatch, localitiesByDepartment]);

  useEffect(() => {
    const localidades = formData.zonaGeografica?.localidades || {};
    const hasCustomLocations = Object.keys(localidades).some(id => id.startsWith('custom_'));
    
    if (hasCustomLocations) {
      const updatedLocalidades = { ...localidades };
      let hasChanges = false;

      Object.entries(localidades).forEach(([id, data]) => {
        if (id.startsWith('custom_') && !data.nombre && customLocations[id]) {
          updatedLocalidades[id] = {
            ...data,
            nombre: customLocations[id]
          };
          hasChanges = true;
        }
      });

      if (hasChanges) {
        onZonaGeograficaChange('localidades', updatedLocalidades);
      }
    }
  }, [formData.zonaGeografica?.localidades, customLocations, onZonaGeograficaChange]);

  useEffect(() => {
    if (!formData.zonaGeografica) {
      onZonaGeograficaChange('departamentos', departamentoSeleccionado ? [parseInt(departamentoSeleccionado)] : []);
      onZonaGeograficaChange('localidades', {});
    }
  }, [formData, departamentoSeleccionado, onZonaGeograficaChange]);

  useEffect(() => {
    if (Object.keys(formData.zonaGeografica?.localidades || {}).length > 0) {
      setMostrarLocalidades(true);
    }
  }, [formData.zonaGeografica?.localidades]);

  useEffect(() => {
    let totalAsignado = 0;
    const presupuestoTotal = formData.presupuesto?.monto || 0;
    
    if (mostrarLocalidades) {
      totalAsignado = Object.values(formData.zonaGeografica?.localidades || {})
        .reduce((sum, value) => sum + (parseFloat(value.monto) || 0), 0);
    }
    
    // Permitimos una pequeña diferencia de redondeo (0.01)
    const diferencia = Math.abs(presupuestoTotal - totalAsignado);
    const esMontoValido = diferencia <= 0.01;
    
    setPresupuestoRestante(presupuestoTotal - totalAsignado);
    setPorcentajeAsignado(((totalAsignado / presupuestoTotal) * 100) || 0);

    // Si hay error de monto total, lo reportamos
    if (onChange && totalAsignado > 0) {
      onChange('montoTotalValido', esMontoValido);
    }
  }, [formData.zonaGeografica?.localidades, formData.presupuesto?.monto, mostrarLocalidades, onChange]);

  // Render
  return (
    <div className="step-container">
      <div className="form-section mb-4">
        <h2 className="section-title text-center mb-3">Zona Geográfica</h2>
        <div className="section-subtitle text-center mb-4">Especifica el departamento dónde deseas publicitar</div>

        <div className="department-selector">
          <Form.Group>
            <Form.Label>Departamento</Form.Label>
            <Form.Control
              value="SAN JOSE"
              readOnly
              disabled
            />
            <FormError error={errors['departamento']} />
          </Form.Group>
        </div>
      </div>

      {departamentoSeleccionado && localitiesByDepartment[departamentoSeleccionado] && (
        <div className="form-section">
          <h2 className="section-title">Especifica las localidades dónde deseas publicitar</h2>
          <div className="payment-options mb-4">
          <div
            className={`payment-option ${
              !mostrarLocalidades ? "active" : ""
            }`}
            onClick={() => handleMostrarLocalidadesChange(false)}
            style={{
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Form.Check
              type="radio"
              id="todasLocalidades"
              label="Publicitar en todas las localidades"
              name="distribucionLocalidades"
              checked={!mostrarLocalidades}
              onChange={() => {}}
            />
            <small
              className="option-description"
              style={{ textAlign: "center" }}
            >
              La publicidad alcanzará a todo el departamento
            </small>
          </div>

          <div
            className={`payment-option ${
              mostrarLocalidades ? "active" : ""
            }`}
            onClick={() => handleMostrarLocalidadesChange(true)}
            style={{
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Form.Check
              type="radio"
              id="seleccionarLocalidades"
              label="Seleccionar localidades"
              name="distribucionLocalidades"
              checked={mostrarLocalidades}
              onChange={() => {}}
            />
            <small
              className="option-description"
              style={{ textAlign: "center" }}
            >
              Selecciona las localidades específicas y distribuye tu presupuesto manualmente
            </small>
          </div>
        </div>
          {mostrarLocalidades && (
            <div className="locations-section">
              <div className="locations-header">
                <div className="d-flex justify-content-center align-items-center mb-3">
                  <button
                    type="button"
                    className="btn btn-primary me-2"
                    onClick={() => setShowLocationSelector(true)}
                  >
                    <i className="bi bi-plus-circle me-2"></i>
                    Agregar localidad
                  </button>
                </div>
                
                <FormError error={errors.zonaGeografica} />

                {showLocationSelector && !mostrarInputOtraLocalidad && (
                  <div className="location-selector mt-3">
                    <Form.Group>
                      <Form.Label className="mb-2">Localidad</Form.Label>
                      <Form.Select
                        className="form-select"
                        style={{
                          border: '1px solid #ced4da',
                          borderRadius: '4px',
                          transition: 'all 0.2s ease',
                          boxShadow: 'none'
                        }}
                        onFocus={(e) => {
                          e.currentTarget.style.borderColor = '#0d6efd';
                          e.currentTarget.style.boxShadow = '0 0 0 0.2rem rgba(13, 110, 253, 0.25)';
                        }}
                        onBlur={(e) => {
                          e.currentTarget.style.borderColor = '#ced4da';
                          e.currentTarget.style.boxShadow = 'none';
                        }}
                        onChange={(e) => agregarLocalidad(e.target.value)}
                        value=""
                      >
                        <option value="">Seleccione una localidad</option>
                        {getLocalidadesDisponibles().map((loc) => (
                          <option key={loc.id} value={loc.id}>
                            {loc.nombre}
                          </option>
                        ))}
                        <option value="otra">Otra localidad</option>
                      </Form.Select>
                      <Form.Text className="text-muted mt-1">
                        Seleccione o agregue una nueva localidad
                      </Form.Text>
                    </Form.Group>
                  </div>
                )}

                {mostrarInputOtraLocalidad && (
                  <div className="custom-location-input mt-3">
                    <div className="d-flex gap-2">
                      <Form.Control
                        type="text"
                        placeholder="Nombre de la localidad"
                        value={nombreOtraLocalidad}
                        onChange={(e) => setNombreOtraLocalidad(e.target.value)}
                      />
                      <button
                        className="btn btn-outline-primary"
                        onClick={agregarOtraLocalidad}
                      >
                        Agregar
                      </button>
                      <button
                        className="btn btn-outline-secondary"
                        onClick={() => {
                          setMostrarInputOtraLocalidad(false);
                          setNombreOtraLocalidad('');
                        }}
                      >
                        Cancelar
                      </button>
                    </div>
                    <FormError error={errors['nombreOtraLocalidad']} />
                  </div>
                )}
              </div>

              <div className="locations-list mt-3">
                <div className="budget-summary card mb-3">
                  <div className="card-body p-3">
                    <div className="mb-3">
                      <div className="row g-3 align-items-center">
                        <div className="col-12 col-md-8">
                          <h6 className="mb-2">Distribución del Presupuesto</h6>
                          <div className="progress" style={{ height: '20px', backgroundColor: '#e9ecef' }}>
                            <div 
                              className="progress-bar progress-bar-custom" 
                              role="progressbar"
                              style={{ 
                                width: `${porcentajeAsignado}%`,
                                backgroundColor: porcentajeAsignado > 100 ? '#dc3545' : '#0b3357'
                              }}
                              aria-valuenow={porcentajeAsignado}
                              aria-valuemin="0"
                              aria-valuemax="100">
                              {porcentajeAsignado.toFixed(1)}% asignado
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-4 text-md-end">
                          <button
                            type="button"
                            className="btn w-100 w-md-auto btn-custom-primary border"
                            onClick={distribuirEquitativamente}
                            title="Distribuir el presupuesto equitativamente entre todas las localidades"
                          >
                            <i className="bi bi-arrow-left-right me-2"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                    
                    <hr className="my-2" />
                    
                    <div className="row g-2 align-items-center">
                      <div className="col-6">
                        <div className="text-muted small mb-1">Presupuesto total</div>
                        <div className="d-flex align-items-center">
                          <span className="h5 mb-0">{formData.presupuesto?.moneda}</span>
                          <span className="h5 mb-0 ms-1">{formData.presupuesto?.monto.toLocaleString()}</span>
                        </div>
                      </div>
                      <div className="col-6 text-end">
                        <div className="text-muted small mb-1">Restante</div>
                        <div className="d-flex align-items-center justify-content-end">
                          <span className="h5 mb-0">{formData.presupuesto?.moneda}</span>
                          <span className={`h5 mb-0 ms-1 ${presupuestoRestante < 0 ? 'text-danger' : 'text-success'}`}>
                            {presupuestoRestante.toLocaleString()}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {Object.entries(formData.zonaGeografica?.localidades || {}).map(([localidadId, localidad]) => (
                  <div key={localidadId} className="location-item card mb-3 shadow-sm">
                    <div className="card-body">
                      <div className="mb-3">
                        <h5 className="mb-0 w-100">{getLocalidadNombre(localidadId)}</h5>
                      </div>
                      <div className="d-flex justify-content-end align-items-center">
                        <div className="input-group" style={{ maxWidth: '200px' }}>
                          <span className="input-group-text">{formData.presupuesto?.moneda}</span>
                          <input
                            type="text"
                            className={`form-control form-control-lg text-end ${
                              parseFloat(localidad.monto || 0) > formData.presupuesto?.monto ? 'is-invalid' : ''
                            }`}
                            value={localidad.monto || ''}
                            onChange={(e) => handleLocalidadPresupuestoChange(localidadId, e.target.value)}
                            placeholder="0.00"
                          />
                          <button
                            className="btn btn-outline-danger"
                            onClick={() => {
                              const newLocalidades = { ...(formData.zonaGeografica?.localidades || {}) };
                              delete newLocalidades[localidadId];
                              onZonaGeograficaChange('localidades', newLocalidades);
                            }}
                            type="button"
                            title="Eliminar localidad"
                          >
                            <i className="bi bi-trash-fill"></i>
                          </button>
                        </div>
                      </div>
                      {parseFloat(localidad.monto || 0) > 0 && (
                        <div className="mt-2">
                          <div className="progress" style={{ height: '8px' }}>
                            <div
                              className="progress-bar progress-bar-custom"
                              role="progressbar"
                              style={{
                                width: `${((parseFloat(localidad.monto) || 0) / formData.presupuesto?.monto) * 100}%`
                              }}
                            />
                          </div>
                          <small className="text-muted">
                            {(((parseFloat(localidad.monto) || 0) / formData.presupuesto?.monto) * 100).toFixed(1)}% del total
                          </small>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
});

export default memo(Step4);
