// src/components/LandingPage.js
import React, { useState, useEffect, useRef, lazy, Suspense, useCallback, useMemo } from "react";
import "./LandingPage.css";
import Header from "./Header";
import HeroSection from "./HeroSection";
import ContactForm from './ContactForm';
const TimelineSection = lazy(() => import("./TimelineSection"));
const Steps = lazy(() => import("./Steps"));

// Función debounce fuera del componente para evitar recraciones
function debounce(func, wait) {
  let timeout;
  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

// Función throttle para limitar frecuencia de ejecución
function throttle(func, limit) {
  let inThrottle;
  return function(...args) {
    if (!inThrottle) {
      func(...args);
      inThrottle = true;
      setTimeout(() => inThrottle = false, limit);
    }
  };
}

const LandingPage = () => {
  const [showWelcome, setShowWelcome] = useState(true);
  const servicesRef = useRef(null);
  const testimonialsRef = useRef(null);
  const contactRef = useRef(null);
  const svgRef = useRef(null);
  const stepsRef = useRef(null);
  const circlesRef = useRef({});
  const frameIdRef = useRef(null);

  // Función de actualización de líneas optimizada con useCallback
  const updateLines = useCallback(() => {
    const svg = svgRef.current;
    if (!svg) return;

    const items = document.querySelectorAll(".graph-item");
    const circles = {};
    const graphContainer = document.querySelector(".graph-container");
    const containerRect = graphContainer?.getBoundingClientRect();
    
    if (!containerRect) return;

    // Calculamos las posiciones solo una vez
    items.forEach((item) => {
      const index = item.dataset.index;
      const circle = item.querySelector(".circle");
      const circleRect = circle.getBoundingClientRect();

      const centerX =
        circleRect.left + circleRect.width / 2 - containerRect.left;
      const centerY =
        circleRect.top + circleRect.height / 2 - containerRect.top;

      circles[index] = {
        x: (centerX / containerRect.width) * svg.viewBox.baseVal.width,
        y: (centerY / containerRect.height) * svg.viewBox.baseVal.height,
      };
    });

    // Actualizamos el ref para no recalcular en cada frame
    circlesRef.current = circles;

    const drawLine = (line, startIndex, endIndex) => {
      if (circles[startIndex] && circles[endIndex]) {
        const start = circles[startIndex];
        const end = circles[endIndex];

        line.setAttribute(
          "d",
          `M${start.x.toFixed(2)},${start.y.toFixed(2)} L${end.x.toFixed(
            2
          )},${end.y.toFixed(2)}`
        );
      }
    };

    drawLine(svg.querySelector(".line-1-2"), "1", "2");
    drawLine(svg.querySelector(".line-2-3"), "2", "3");
    drawLine(svg.querySelector(".line-3-4"), "3", "4");
  }, []);

  // Debounce con throttle para evitar múltiples actualizaciones
  const debouncedUpdateLines = useMemo(() => 
    debounce(updateLines, 250), [updateLines]);

  const throttledResize = useMemo(() => 
    throttle(() => {
      // Cancelamos cualquier frame pendiente para evitar actualizaciones innecesarias
      if (frameIdRef.current) {
        cancelAnimationFrame(frameIdRef.current);
      }
      
      // Programamos la actualización en el próximo frame de animación
      frameIdRef.current = requestAnimationFrame(debouncedUpdateLines);
    }, 100), [debouncedUpdateLines]);

  useEffect(() => {
    if (!showWelcome) return;

    // Primera actualización después de un breve retraso para permitir renderizado
    const initialUpdate = setTimeout(updateLines, 100);
    
    // Usamos el throttle para el evento resize
    window.addEventListener("resize", throttledResize);
    
    return () => {
      clearTimeout(initialUpdate);
      window.removeEventListener("resize", throttledResize);
      
      // Limpiamos cualquier frame pendiente
      if (frameIdRef.current) {
        cancelAnimationFrame(frameIdRef.current);
      }
    };
  }, [showWelcome, updateLines, throttledResize]);

  const handleGoToForm = useCallback(() => {
    setShowWelcome(false);
  }, []);

  const LoadingFallback = useMemo(() => () => (
    <div style={{ 
      height: "400px", 
      display: "flex", 
      alignItems: "center", 
      justifyContent: "center",
      background: "linear-gradient(135deg, rgba(11, 51, 87, 0.05) 0%, rgba(11, 51, 87, 0.1) 100%)"
    }}>
      <div style={{ color: "#0b3357" }}>Cargando...</div>
    </div>
  ), []);

  return (
    <div className="landing-page">
      <Header
        servicesRef={servicesRef}
        testimonialsRef={testimonialsRef}
        contactRef={contactRef}
      />
      <HeroSection 
        showWelcome={showWelcome} 
        handleGoToForm={handleGoToForm}
        stepsRef={stepsRef} 
      />
      <Suspense fallback={<LoadingFallback />}>
        <div ref={stepsRef}>
          <Steps showWelcome={showWelcome} />
        </div>
      </Suspense>
      <Suspense fallback={<LoadingFallback />}>
        <TimelineSection />
      </Suspense>
      <section className="contact-section" ref={contactRef}>
        <h1>Contacto</h1>
        <p>
          ¿Tienes alguna pregunta? Contáctanos a través de nuestro formulario o
          envíanos un correo electrónico a{" "}
          <a href="mailto:contacto@platad.com">contacto@platad.com</a>.
        </p>
        <ContactForm />
      </section>
    </div>
  );
};

export default React.memo(LandingPage);
