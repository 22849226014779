import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { submitAdRequest } from "../redux/adRequestSlice";
import Step1 from "./FormStep1";
import Step2 from "./FormStep2";
import Step3 from "./FormStep3";
import Step4 from "./FormStep4";
import Step5 from "./FormStep5";
import "./AdRequestForm.css";
import { Container, Alert } from "react-bootstrap";
import keycloak from "../keycloak";
import { step1Schema } from "../validations/step1Schema";
import { step2Schema } from '../validations/step2Schema';
import { step3Schema } from '../validations/step3Schema';
import { step4Schema } from '../validations/step4Schema';
import { step5Schema } from '../validations/step5Schema';
import { debounce } from 'lodash';

const AdRequestForm = ({ isLoggedIn, defaultMoneda, defaultPeriodicidadPago }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useSelector((state) => state.adRequest);
  const [step, setStep] = useState(() => {
    // Intentar recuperar el paso guardado del localStorage
    const savedStep = localStorage.getItem('adRequestFormStep');
    return savedStep ? parseInt(savedStep, 10) : 1;
  });

  // Guardar el paso actual cuando cambie
  useEffect(() => {
    localStorage.setItem('adRequestFormStep', step.toString());
  }, [step]);

  const [formErrors, setFormErrors] = useState({});
  const [completedSteps, setCompletedSteps] = useState([]);
  const [formData, setFormData] = useState(() => {
    // Inicializar el estado del formulario
    const initialFormData = {
      anuncioPersigue: "",
      audiencia: [],
      audienciaOtro: "",
      rubro: "",
      rubroId: "",
      rubroOtro: "",
      mediosDifusion: [],
      mediosOtro: "",
      presupuesto: {
        moneda: defaultMoneda || "$",
        monto: ""
      },
      periodicidadPago: defaultPeriodicidadPago || "simple",
      cantidadMeses: 1,
      zonaGeografica: {
        departamentos: [],
        localidades: {},
      },
      fechaLimitePostulacion: "",
      descripcionProducto: "",
      tienePiezaPublicitaria: "",
      fechaInicioAnuncio: "",
      fechaFinAnuncio: "",
      tipoMaterial: "",
      duracionMaterial: "",
      duracionCustom: "",
      piezaPublicitariaOtro: "",
      usuario: keycloak.tokenParsed?.email || "", 
    };

    // Intentar cargar datos guardados
    try {
      const savedData = localStorage.getItem('adRequestFormData');
      if (savedData) {
        const parsedData = JSON.parse(savedData);
        return {
          ...initialFormData,
          ...parsedData
        };
      }
    } catch (error) {
      console.error('Error al cargar datos guardados:', error);
    }
    // Si no hay datos guardados, usar los valores iniciales
    return initialFormData;
  });

  // Crear una versión debounced de la función de guardado
  const debouncedSaveToLocalStorage = useMemo(
    () =>
      debounce((data) => {
        localStorage.setItem('adRequestFormData', JSON.stringify(data));
      }, 1000),
    []
  );

  // Usar la versión debounced en el efecto
  useEffect(() => {
    debouncedSaveToLocalStorage(formData);
    
    // Cleanup function
    return () => {
      debouncedSaveToLocalStorage.cancel();
    };
  }, [formData, debouncedSaveToLocalStorage]);

  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    // Update user email once Keycloak is initialized
    if (keycloak.authenticated && keycloak.tokenParsed) {
      setFormData(prevData => ({
        ...prevData,
        usuario: keycloak.tokenParsed.email
      }));
      
      // Verificar si hay un envío pendiente después de la autenticación
      const hasPendingSubmit = localStorage.getItem('adRequestFormPendingSubmit') === 'true';
      if (hasPendingSubmit) {
        // Limpiar el flag de envío pendiente antes de proceder
        localStorage.removeItem('adRequestFormPendingSubmit');
        
        // Enviar directamente sin validar (ya se validó antes del login)
        dispatch(submitAdRequest({ formData: JSON.parse(localStorage.getItem('adRequestFormData')) }))
          .unwrap()
          .then(() => {
            setShowSuccess(true);
            // Limpiar todos los datos guardados
            localStorage.removeItem('adRequestFormData');
            localStorage.removeItem('adRequestFormStep');
            localStorage.removeItem('adRequestFormPendingSubmit');
            
            // Resetear el formulario a valores iniciales
            setFormData({
              anuncioPersigue: "",
              audiencia: [],
              audienciaOtro: "",
              rubro: "",
              rubroId: "",
              rubroOtro: "",
              mediosDifusion: [],
              mediosOtro: "",
              presupuesto: {
                moneda: defaultMoneda || "$",
                monto: ""
              },
              periodicidadPago: defaultPeriodicidadPago || "simple",
              cantidadMeses: 1,
              zonaGeografica: {
                departamentos: [],
                localidades: {},
              },
              fechaLimitePostulacion: "",
              descripcionProducto: "",
              tienePiezaPublicitaria: "",
              fechaInicioAnuncio: "",
              fechaFinAnuncio: "",
              tipoMaterial: "",
              duracionMaterial: "",
              duracionCustom: "",
              piezaPublicitariaOtro: "",
              usuario: keycloak.tokenParsed?.email || "", 
            });
            
            // Resetear también otros estados relacionados con el formulario
            setStep(1);
            setFormErrors({});
            setCompletedSteps([]);
            
            setTimeout(() => {
              navigate("/mis-anuncios");
            }, 3000);
          })
          .catch((error) => {
            console.error("Error al enviar la solicitud:", error);
            setIsSubmitting(false);
          });
      }
    }
  }, [keycloak.authenticated, keycloak.tokenParsed, dispatch, defaultMoneda, defaultPeriodicidadPago, navigate]);

  const [showSuccess, setShowSuccess] = useState(false);
  
  const steps = [
    { number: 1, title: "Objetivo" },
    { number: 2, title: "Medios" },
    { number: 3, title: "Presupuesto" },
    { number: 4, title: "Ubicación" },
    { number: 5, title: "Detalles" },
  ];

  // Memoizar los esquemas de validación
  const validationSchemas = useMemo(() => ({
    1: step1Schema,
    2: step2Schema,
    3: step3Schema,
    4: step4Schema,
    5: step5Schema
  }), []);

  const validateStep = useCallback(async (stepNumber, data) => {
    const schema = validationSchemas[stepNumber];
    if (!schema) return { isValid: true };

    try {
      // Solo validar los campos relevantes para el paso actual
      const relevantData = {};
      switch (stepNumber) {
        case 1:
          relevantData.anuncioPersigue = data.anuncioPersigue;
          relevantData.audiencia = data.audiencia;
          relevantData.audienciaOtro = data.audienciaOtro;
          relevantData.rubro = data.rubro;
          relevantData.rubroId = data.rubroId;
          break;
        case 2:
          relevantData.mediosDifusion = data.mediosDifusion;
          relevantData.mediosOtro = data.mediosOtro;
          break;
        case 3:
          relevantData.presupuesto = data.presupuesto;
          relevantData.periodicidadPago = data.periodicidadPago;
          relevantData.cantidadMeses = data.cantidadMeses;
          break;
        case 4:
          relevantData.zonaGeografica = data.zonaGeografica;
          relevantData.presupuesto = data.presupuesto; 
          break;
        case 5:
          relevantData.fechaLimitePostulacion = data.fechaLimitePostulacion;
          relevantData.descripcionProducto = data.descripcionProducto;
          relevantData.tienePiezaPublicitaria = data.tienePiezaPublicitaria;
          relevantData.tipoMaterial = data.tipoMaterial;
          relevantData.duracionMaterial = data.duracionMaterial;
          relevantData.duracionCustom = data.duracionCustom;
          relevantData.piezaPublicitariaOtro = data.piezaPublicitariaOtro;
          relevantData.fechaInicioAnuncio = data.fechaInicioAnuncio;
          relevantData.fechaFinAnuncio = data.fechaFinAnuncio;
          break;
      }

      await schema.validate(relevantData, { abortEarly: false });
      return { isValid: true };
    } catch (error) {
      const errors = {};
      if (error.inner) {
        error.inner.forEach((err) => {
          errors[err.path] = err.message;
        });
      }
      return { isValid: false, errors };
    }
  }, [validationSchemas]);

  const getStepData = (stepNumber) => {
    let data;
    switch (stepNumber) {
      case 1:
        data = {
          anuncioPersigue: formData.anuncioPersigue || "",
          audiencia: Array.isArray(formData.audiencia) ? formData.audiencia : [],
          audienciaOtro: formData.audienciaOtro || "",
          rubro: formData.rubro || "",
          rubroId: formData.rubroId || ""
        };
        console.log('Datos del paso 1:', data);
        return data;
      case 2:
        data = {
          mediosDifusion: Array.isArray(formData.mediosDifusion) ? formData.mediosDifusion : [],
          mediosOtro: formData.mediosOtro || ""
        };
        console.log('Datos del paso 2:', data);
        return data;
      case 3:
        data = {
          presupuesto: formData.presupuesto,
          periodicidadPago: formData.periodicidadPago,
          cantidadMeses: formData.cantidadMeses
        };
        console.log('Datos del paso 3:', data);
        return data;
      case 4:
        data = {
          zonaGeografica: formData.zonaGeografica,
          presupuesto: formData.presupuesto
        };
        console.log('Datos del paso 4:', JSON.stringify(data, null, 2));
        return data;
      case 5:
        data = {
          fechaLimitePostulacion: formData.fechaLimitePostulacion || "",
          descripcionProducto: formData.descripcionProducto || "",
          tienePiezaPublicitaria: formData.tienePiezaPublicitaria || "",
          tipoMaterial: formData.tipoMaterial || "",
          duracionMaterial: formData.duracionMaterial || "",
          duracionCustom: formData.duracionCustom || "",
          piezaPublicitariaOtro: formData.piezaPublicitariaOtro || "",
          fechaInicioAnuncio: formData.fechaInicioAnuncio || "",
          fechaFinAnuncio: formData.fechaFinAnuncio || "",
        };
        console.log('Datos del paso 5:', data);
        return data;
      default:
        return {};
    }
  };

  const handleStepClick = async (stepNumber) => {
    // Si intentamos ir hacia adelante
    if (stepNumber > step) {
      // Validar el paso actual antes de permitir avanzar
      const stepData = getStepData(step);
      const { isValid, errors } = await validateStep(step, stepData);
      
      if (!isValid) {
        setFormErrors(errors);
        return;
      }
      
      // Si la validación es exitosa
      setFormErrors({});
      if (!completedSteps.includes(step)) {
        setCompletedSteps([...completedSteps, step]);
      }
    }
    
    setStep(stepNumber);
  };

  const handleChange = useCallback((field, value) => {
    setFormData(prev => ({
      ...prev,
      [field]: value
    }));
  }, [setFormData]);

  const handlePresupuestoChange = useCallback((field, value) => {
    setFormData(prev => ({
      ...prev,
      presupuesto: {
        ...prev.presupuesto,
        [field]: value
      }
    }));
  }, [setFormData]);

  const handleZonaGeograficaChange = useCallback((field, value) => {
    setFormData(prev => ({
      ...prev,
      zonaGeografica: {
        ...prev.zonaGeografica,
        [field]: value
      }
    }));
  }, [setFormData]);

  const currentStepComponent = useMemo(() => {
    const props = {
      formData,
      onChange: handleChange,
      onPresupuestoChange: handlePresupuestoChange,
      onZonaGeograficaChange: handleZonaGeograficaChange,
      errors: formErrors,
      setFormErrors
    };

    switch (step) {
      case 1:
        return <Step1 {...props} />;
      case 2:
        return <Step2 {...props} />;
      case 3:
        return <Step3 {...props} />;
      case 4:
        return <Step4 {...props} />;
      case 5:
        return <Step5 {...props} />;
      default:
        return null;
    }
  }, [step, formData, handleChange, handlePresupuestoChange, handleZonaGeograficaChange, formErrors, setFormErrors]);

  const handleSubmit = async (e) => {
    if (e) e.preventDefault();
    
    if (isSubmitting) return;
    setIsSubmitting(true);
    
    // Solo validar si no estamos volviendo de una autenticación
    const hasPendingSubmit = localStorage.getItem('adRequestFormPendingSubmit') === 'true';
    if (!hasPendingSubmit) {
      // Validar el formulario antes de cualquier acción
      const stepData = getStepData(5);
      const { isValid, errors } = await validateStep(5, stepData);
      
      if (!isValid) {
        setFormErrors(errors);
        setIsSubmitting(false);
        return;
      }

      // Limpiar errores si la validación fue exitosa
      setFormErrors({});
    }
    
    if (!isLoggedIn) {
      try {
        localStorage.setItem('adRequestFormStep', '5');
        localStorage.setItem('adRequestFormPendingSubmit', 'true');
        setShowSuccess(true);
        setTimeout(() => {
          keycloak.login();
        }, 1500);
        return;
      } catch (error) {
        console.error('Error during login:', error);
        setIsSubmitting(false);
        return;
      }
    }

    try {
      await dispatch(submitAdRequest({ formData: JSON.parse(localStorage.getItem('adRequestFormData')) })).unwrap();
      setShowSuccess(true);
      
      // Eliminar datos del localStorage
      localStorage.removeItem('adRequestFormData');
      localStorage.removeItem('adRequestFormStep');
      localStorage.removeItem('adRequestFormPendingSubmit');
      
      // Resetear el formulario a valores iniciales
      setFormData({
        anuncioPersigue: "",
        audiencia: [],
        audienciaOtro: "",
        rubro: "",
        rubroId: "",
        rubroOtro: "",
        mediosDifusion: [],
        mediosOtro: "",
        presupuesto: {
          moneda: defaultMoneda || "$",
          monto: ""
        },
        periodicidadPago: defaultPeriodicidadPago || "simple",
        cantidadMeses: 1,
        zonaGeografica: {
          departamentos: [],
          localidades: {},
        },
        fechaLimitePostulacion: "",
        descripcionProducto: "",
        tienePiezaPublicitaria: "",
        fechaInicioAnuncio: "",
        fechaFinAnuncio: "",
        tipoMaterial: "",
        duracionMaterial: "",
        duracionCustom: "",
        piezaPublicitariaOtro: "",
        usuario: keycloak.tokenParsed?.email || "", 
      });
      
      // Resetear también otros estados relacionados con el formulario
      setStep(1);
      setFormErrors({});
      setCompletedSteps([]);
      
      setTimeout(() => {
        navigate("/mis-anuncios");
      }, 3000);
    } catch (error) {
      console.error("Error al enviar la solicitud:", error);
      setIsSubmitting(false);
    }
  };

  const renderProgressBar = () => (
    <div className="form-progress-bar">
      <div className="progress-steps-container">
        <div className="navigation-buttons">
          {step > 1 ? (
            <button
              className="navigation-button secondary"
              onClick={() => setStep((prev) => Math.max(prev - 1, 1))}
              aria-label="Paso anterior"
              disabled={isSubmitting}
            >
              <span className="d-none d-sm-inline">← Atrás</span>
              <span className="d-inline d-sm-none">←</span>
            </button>
          ) : (
            <button
              className="navigation-button secondary"
              onClick={() => navigate("/")}
              aria-label="Volver al inicio"
              disabled={isSubmitting}
            >
              <span className="d-none d-sm-inline">← Volver</span>
              <span className="d-inline d-sm-none">←</span>
            </button>
          )}
        </div>

        <div className="steps-wrapper">
          {steps.map((s) => (
            <div
              key={s.number}
              className={`step-item ${step === s.number ? "active" : ""} ${
                step > s.number ? "completed" : ""
              } ${(s.number > step && !completedSteps.includes(s.number - 1)) || isSubmitting ? "disabled" : ""}`}
              data-step={s.number}
              onClick={() => !isSubmitting && handleStepClick(s.number)}
              style={{ cursor: (s.number > step && !completedSteps.includes(s.number - 1)) || isSubmitting ? "not-allowed" : "pointer" }}
            >
              <div className="step-number">
                {step > s.number ? "✓" : s.number}
              </div>
              {step === s.number && (
                <div className="step-title">{s.title}</div>
              )}
            </div>
          ))}
        </div>

        <div className="navigation-buttons">
          {step === 5 ? (
            <button
              className="navigation-button primary"
              onClick={handleSubmit}
              aria-label="Enviar formulario"
              disabled={isSubmitting}
            >
              <span className="d-none d-sm-inline">
                {isSubmitting ? "Enviando..." : "Crear ✓"}
              </span>
              <span className="d-inline d-sm-none">
                {isSubmitting ? "..." : "Crear"}
              </span>
            </button>
          ) : (
            <button
              className="navigation-button primary"
              onClick={async () => {
                if (isSubmitting) return;
                console.log('Iniciando validación del paso:', step);
                const stepData = getStepData(step);
                const { isValid, errors } = await validateStep(step, stepData);
                console.log('Resultado de validación:', { isValid, errors });
                
                if (!isValid) {
                  setFormErrors(errors);
                  return;
                }
                
                setFormErrors({});
                if (!completedSteps.includes(step)) {
                  setCompletedSteps(prev => [...prev, step]);
                }
                setStep(prev => Math.min(prev + 1, 5));
              }}
              aria-label="Siguiente paso"
              disabled={isSubmitting}
            >
              <span className="d-none d-sm-inline">Siguiente →</span>
              <span className="d-inline d-sm-none">→</span>
            </button>
          )}
        </div>
      </div>
    </div>
  );

  return (
    <>
      <Container>
        {showSuccess && !isLoggedIn ? (
          <div className="success-message">
            <Alert variant="info" className="text-center">
              <Alert.Heading>Un momento por favor</Alert.Heading>
              <p>Para crear tu anuncio necesitas iniciar sesión primero.</p>
              <p>Te redirigiremos al inicio de sesión y luego podrás continuar con la creación del anuncio...</p>
            </Alert>
          </div>
        ) : showSuccess && (
          <div className="success-message">
            <Alert variant="success" className="text-center">
              <Alert.Heading>¡Felicitaciones!</Alert.Heading>
              <p>Tu solicitud de anuncio ha sido enviada exitosamente.</p>
              <p>Serás redirigido a tus anuncios en unos momentos...</p>
            </Alert>
          </div>
        )}

        <div className="ad-request-form-container" style={{ opacity: isSubmitting ? 0.7 : 1, pointerEvents: isSubmitting ? 'none' : 'auto' }}>
          <div className="ad-request-form">
            <div className="progress-bar-top">
              {renderProgressBar()}
            </div>
            <div className="form-content">{currentStepComponent}</div>
            <div className="progress-bar-bottom">
              {renderProgressBar()}
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default React.memo(AdRequestForm);
