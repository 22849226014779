import React, { useEffect, useCallback, memo } from "react";
import { Form } from "react-bootstrap";
import { useSelector, useDispatch } from 'react-redux';
import { fetchMedios } from '../redux/mediosSlice';
import FormError from "./common/FormError";

const Step2 = ({ formData, onChange, errors = {} }) => {
  const dispatch = useDispatch();
  const { medios, status } = useSelector((state) => state.medios);

  useEffect(() => {
    dispatch(fetchMedios());
  }, [dispatch]);

  const handleMediaChange = useCallback((medio, isChecked) => {
    let newMedios = [...(formData.mediosDifusion || [])];

    if (isChecked) {
      newMedios.push(medio.id);
    } else {
      newMedios = newMedios.filter((id) => id !== medio.id);
      if (medio.descripcion === "Otros") {
        onChange("mediosOtro", "");
      }
    }

    onChange("mediosDifusion", newMedios);
  }, [formData.mediosDifusion, onChange]);

  const handleCustomMediaChange = useCallback((e) => {
    const value = e.target.value;
    onChange("mediosOtro", value);
  }, [onChange]);

  const getIconForMedia = useCallback((media) => {
    switch (media) {
      case "Radio":
        return "📻";
      case "Televisión":
        return "📺";
      case "Diarios":
        return "📰";
      case "Medios Digitales":
        return "🌐";
      case "Vía Pública":
        return "🚌";
      case "Otros":
        return "💡";
      default:
        return "💡";
    }
  }, []);

  return (
    <div className="step-container">
      <div className="form-section mb-4">
        <h2 className="section-title text-center mb-3">Medios de difusión</h2>
        <div className="section-subtitle text-center mb-4">
          Selecciona los medios donde te gustaría publicar tu anuncio
        </div>

        <FormError error={errors.mediosDifusion} />
        <div className="media-options mx-auto" style={{ maxWidth: "800px" }}>
          {status === 'succeeded' && medios.map((option) => (
            <div
              key={option.id}
              className={`audience-option ${
                formData.mediosDifusion?.includes(option.id) ? "active" : ""
              } ${errors.mediosDifusion ? 'is-invalid' : ''}`}
              onClick={() =>
                handleMediaChange(
                  option,
                  !formData.mediosDifusion?.includes(option.id)
                )
              }
            >
              <Form.Check
                type="checkbox"
                id={`media-${option.id}`}
                checked={formData.mediosDifusion?.includes(option.id)}
                onChange={() => {}}
                onClick={(e) => e.stopPropagation()}
                label={
                  <div className="option-content">
                    <div className="option-header">
                      {getIconForMedia(option.descripcion)}{" "}
                      {option.descripcion}
                    </div>
                    <div className="option-description">
                      {option.detalle}
                    </div>
                  </div>
                }
              />
              {option.descripcion === "Otros" &&
                formData.mediosDifusion?.includes(option.id) && (
                  <div
                    className="custom-input-wrapper"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <FormError error={errors.mediosOtro} />
                    <Form.Control
                      type="text"
                      value={formData.mediosOtro || ""}
                      onChange={handleCustomMediaChange}
                      placeholder="Especifica otros medios de difusión"
                      className={`custom-input ${errors.mediosOtro ? 'is-invalid' : ''}`}
                    />
                  </div>
                )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default memo(Step2);
