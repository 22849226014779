import * as Yup from 'yup';

// Esquema de validación para el paso 1
export const step1Schema = Yup.object().shape({
  anuncioPersigue: Yup.string()
    .required('Por favor, describe el objetivo de tu anuncio')
    .min(10, 'La descripción debe tener al menos 10 caracteres')
    .max(500, 'La descripción no debe exceder los 500 caracteres'),
  audiencia: Yup.array()
    .min(1, 'Selecciona al menos un tipo de audiencia')
    .required('Selecciona al menos un tipo de audiencia'),
  audienciaOtro: Yup.string()
    .when(['audiencia'], {
      is: (audiencia) => Array.isArray(audiencia) && audiencia.includes('otro'),
      then: () => Yup.string()
        .required('Por favor, especifica la otra audiencia')
        .min(3, 'La descripción debe tener al menos 3 caracteres')
        .max(100, 'La descripción no debe exceder los 100 caracteres')
        .test({
          name: 'allow-deselection',
          message: 'La descripción debe tener al menos 3 caracteres',
          test: function(value, context) {
            // Si estamos intentando deseleccionar, permitirlo
            if (context.originalValue === value) {
              return true;
            }
            // Si no, aplicar la validación normal
            return value && value.length >= 3;
          }
        }),
      otherwise: () => Yup.string().nullable()
    }),
  rubroId: Yup.string()
    .required('Por favor, selecciona el rubro de tu negocio'),
  rubro: Yup.string()
    .test({
      name: 'rubro-validation',
      message: 'Por favor, especifica el rubro de tu negocio',
      test: function(value, context) {
        const { rubroId } = this.parent;
        
        if (rubroId === 'otro') {
          if (!value || value.trim() === '') {
            return this.createError({
              message: 'Por favor, especifica el rubro de tu negocio'
            });
          }
          
          if (value.length < 3) {
            return this.createError({
              message: 'El rubro debe tener al menos 3 caracteres'
            });
          }
          
          if (value.length > 100) {
            return this.createError({
              message: 'El rubro no debe exceder los 100 caracteres'
            });
          }
        }
        
        return true;
      }
    })
});
