import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, useNavigate } from "react-router-dom";
import Header from "./components/Header";
import LandingPage from "./components/LandingPage";
import Profile from "./pages/Profile";
import AdRequestPage from "./pages/AdRequestPage";
import MyAds from "./pages/MyAds";
import AdDetails from "./pages/AdDetails";
import ProtectedRoute from "./components/ProtectedRoute";
import keycloak from "./keycloak";

function AppContent() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userName, setUserName] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const isAuthenticated = keycloak.authenticated;
    setIsLoggedIn(isAuthenticated);
    
    if (isAuthenticated) {
      setUserName(
        keycloak.tokenParsed.name || keycloak.tokenParsed.preferred_username
      );
    } else {
      // Si el usuario no está autenticado y no está en la landing page ni en ad-request, redirigir a /
      if (window.location.pathname !== '/' && window.location.pathname !== '/ad-request') {
        navigate('/');
      }
    }
  }, [navigate]);

  // Efecto separado para manejar la redirección inicial después del login
  useEffect(() => {
    const handleLoginSuccess = () => {
      if (window.location.pathname === '/') {
        navigate('/mis-anuncios');
      }
    };

    keycloak.onAuthSuccess = handleLoginSuccess;

    return () => {
      keycloak.onAuthSuccess = null;
    };
  }, [navigate]);

  return (
    <>
      <Header isLoggedIn={isLoggedIn} userName={userName} />
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/profile" element={
          <ProtectedRoute>
            <Profile />
          </ProtectedRoute>
        } />
        <Route path="/ad-request" element={<AdRequestPage isLoggedIn={isLoggedIn} />} />
        <Route path="/mis-anuncios" element={
          <ProtectedRoute>
            <MyAds />
          </ProtectedRoute>
        } />
        <Route path="/anuncio/:id" element={
          <ProtectedRoute>
            <AdDetails />
          </ProtectedRoute>
        } />
      </Routes>
    </>
  );
}

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;
