import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  medios: [],
  status: "idle",
  error: null,
};

export const fetchMedios = createAsyncThunk("medios/fetchMedios", async () => {
  const response = await axios.get(
    "https://advinx.ddns.net:8444/anunciante/cod/medios"
  );
  return response.data;
});

const mediosSlice = createSlice({
  name: "medios",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMedios.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchMedios.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.medios = action.payload;
      })
      .addCase(fetchMedios.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default mediosSlice.reducer;
