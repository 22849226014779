import React from 'react';
import AdRequestForm from '../components/AdRequestForm';
import PageHeader from "../components/PageHeader";
import './AdRequestPage.css';

const AdRequestPage = ({ isLoggedIn }) => {
  return (
    <div className="ad-request-page">
      <PageHeader title="Solicitud de Anuncio" />
      <AdRequestForm isLoggedIn={isLoggedIn} />
    </div>
  );
};

export default AdRequestPage;
