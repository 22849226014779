import React from 'react';
import { Navigate } from 'react-router-dom';
import keycloak from '../keycloak';

const ProtectedRoute = ({ children }) => {
  if (!keycloak.authenticated) {
    // Redirigir al inicio si no está autenticado
    return <Navigate to="/" replace />;
  }

  return children;
};

export default ProtectedRoute;
