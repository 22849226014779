import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import InteractiveAnimation from "./InteractiveAnimation";
import AnimatedText from "./AnimatedText";
import "./HeroSection.css";

const HeroSection = ({ showWelcome, stepsRef }) => {
  const navigate = useNavigate();
  const [allowScroll, setAllowScroll] = useState(false);
  const [showAnimation, setShowAnimation] = useState(true);

  const texts = [
    { text: "Optimiza tu presupuesto para publicidad", color: "#FFFFFF" },
    { text: "Accede a los mejores medios y programas", color: "#FFFFFF" },
    { text: "Elige la mejor publicidad con unos pocos clics", color: "#FFFFFF" },
  ];

  const handleLearnMore = () => {
    stepsRef.current?.scrollIntoView({ behavior: 'smooth' });
    setAllowScroll(true);
  };

  useEffect(() => {
    if (!showWelcome) return;

    const handleTouchMove = (event) => {
      if (!allowScroll) {
        event.preventDefault();
      }
    };

    window.addEventListener("touchmove", handleTouchMove, {
      passive: allowScroll,
    });

    return () => {
      window.removeEventListener("touchmove", handleTouchMove);
    };
  }, [allowScroll, showWelcome]);

  const handleGoToFormClick = () => {
    setShowAnimation(false);
    window.location.href = `${process.env.PUBLIC_URL}/ad-request`;
  };

  const ScrollIndicator = () => (
    <div className="scroll-indicator" onClick={handleLearnMore}>
      <div className="scroll-content">
        <div className="scroll-arrow">
          <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"/>
          </svg>
        </div>
        <div className="scroll-text">Saber más</div>
      </div>
    </div>
  );

  return (
    <header className="hero">
      {showAnimation && <InteractiveAnimation />}
      {showWelcome && (
        <div className="animated-text-container">
          <div className="centered-text">
            <AnimatedText texts={texts} showWelcome={showWelcome} />
            <h1>PlatAd</h1>
          </div>
        </div>
      )}
      <div className="buttons-container">
        <button className="cta-button" onClick={handleGoToFormClick}>
          <span>Crear anuncio</span>
        </button>
      </div>
      {showWelcome && <ScrollIndicator />}
    </header>
  );
};

export default HeroSection;
