import * as Yup from 'yup';

export const step3Schema = Yup.object().shape({
  presupuesto: Yup.object().shape({
    moneda: Yup.string()
      .required('Selecciona una moneda')
      .oneOf(['$', 'USD'], 'Moneda inválida'),
    monto: Yup.number()
      .required('Ingresa un monto')
      .min(1, 'El monto debe ser mayor a 0')
      .max(999999999, 'El monto es demasiado alto')
      .typeError('Ingresa un monto válido')
  }),
  periodicidadPago: Yup.string()
    .required('Selecciona un tipo de pago')
    .oneOf(['simple', 'mensual'], 'Tipo de pago inválido'),
  cantidadMeses: Yup.number()
    .when('periodicidadPago', {
      is: 'mensual',
      then: () => Yup.number()
        .required('Ingresa la cantidad de meses')
        .min(1, 'Mínimo 1 mes')
        .max(12, 'Máximo 12 meses')
        .typeError('Ingresa un número válido'),
      otherwise: () => Yup.number().nullable()
    })
});
