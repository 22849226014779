import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import LoginButton from "./LoginButton";
import keycloak from "../keycloak";
import styled from "styled-components";

const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 24px;
  background-color: rgba(255, 255, 255, 0.95);
  color: #333;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2000; /* Aumentado para estar por encima de todo */
  backdrop-filter: blur(8px);
  transition: all 0.3s ease;
  height: 60px; /* Altura fija para el header */

  @media (max-width: 768px) {
    padding: 8px 16px;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
`;

const BrandName = styled.h1`
  font-size: 1.5em;
  margin: 0;
  color: black !important;
`;

const LinksContainer = styled.div`
  display: flex;
  justify-content: center;
  flex: 1;

  @media (max-width: 768px) {
    display: ${(props) => (props.$isOpen ? "flex" : "none")};
    flex-direction: column;
    align-items: flex-start;
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 10px 20px;
    z-index: 1;
  }
`;

const LinkItem = styled.a`
  color: #333;
  text-decoration: none;
  margin: 0 15px;
  font-size: 1em;
  transition: color 0.3s;

  &:hover {
    color: #1b74c5;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const UserContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const UserAvatar = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #1b74c5;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  cursor: pointer;
`;

const UserDropdown = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  padding: 8px 0;
  min-width: 200px;
  display: ${(props) => (props.$isOpen ? "block" : "none")};
  z-index: 1001;
`;

const DropdownItem = styled.button`
  width: 100%;
  padding: 8px 16px;
  border: none;
  background: none;
  text-align: left;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  color: #333;
  text-decoration: none;
  transition: background-color 0.2s;

  &:hover {
    background-color: #f5f5f5;
  }

  svg {
    width: 16px;
    height: 16px;
  }
`;

const HighlightedDropdownItem = styled(DropdownItem)`
  color: #1b74c5;
  font-weight: 500;
  
  &:hover {
    background-color: #e8f3fc;
  }
`;

const Header = ({ servicesRef, testimonialsRef, contactRef }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userName, setUserName] = useState("");
  const [isUserDropdownOpen, setIsUserDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsUserDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  useEffect(() => {
    const updateUserInfo = () => {
      if (keycloak.authenticated) {
        setIsLoggedIn(true);
        setUserName(
          keycloak.tokenParsed.preferred_username ||
            keycloak.tokenParsed.email ||
            "Usuario"
        );
      } else {
        setIsLoggedIn(false);
        setUserName("");
      }
    };

    updateUserInfo();
    keycloak.onAuthSuccess = updateUserInfo;
    keycloak.onAuthLogout = updateUserInfo;
  }, []);

  const getInitials = (name) => {
    return name
      .split(" ")
      .map((word) => word[0])
      .join("")
      .toUpperCase()
      .slice(0, 2);
  };

  const handleLogout = () => {
    setIsUserDropdownOpen(false);
    keycloak.logout();
  };

  const toggleUserDropdown = () => {
    setIsUserDropdownOpen(!isUserDropdownOpen);
  };

  return (
    <HeaderContainer>
      <LogoContainer>
        <Link to="/" style={{ textDecoration: "none" }}>
          <BrandName>PlatAd</BrandName>
        </Link>
      </LogoContainer>

      <ButtonContainer>
        {isLoggedIn ? (
          <UserContainer ref={dropdownRef}>
            <UserAvatar
              onClick={toggleUserDropdown}
              role="button"
              aria-haspopup="true"
              aria-expanded={isUserDropdownOpen}
            >
              {getInitials(userName)}
            </UserAvatar>
            {isUserDropdownOpen && (
              <UserDropdown $isOpen={isUserDropdownOpen}>
                <HighlightedDropdownItem as={Link} to="/ad-request">
                  Crear anuncio
                </HighlightedDropdownItem>
                <DropdownItem as={Link} to="/mis-anuncios">
                  Mis Anuncios
                </DropdownItem>
                <DropdownItem as={Link} to="/profile">
                  Mi Perfil
                </DropdownItem>
                <DropdownItem onClick={handleLogout}>
                  Cerrar sesión
                </DropdownItem>
              </UserDropdown>
            )}
          </UserContainer>
        ) : (
          <LoginButton />
        )}
      </ButtonContainer>
    </HeaderContainer>
  );
};

export default Header;
