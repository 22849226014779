import React, { useEffect, useRef, useCallback } from "react";

const InteractiveAnimation = ({ allowScroll, opacity }) => {
  const canvasRef = useRef(null);
  const animationFrameRef = useRef(null);
  const circlesRef = useRef([]);
  const particlesRef = useRef([]);
  const mouseRef = useRef({ x: 0, y: 0 });
  const resizeTimeoutRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    // Configurar el canvas
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    // Restablecer los estilos del canvas
    canvas.style.position = 'fixed';
    canvas.style.top = '0';
    canvas.style.left = '0';
    canvas.style.zIndex = '-1';

    const isMobile = window.innerWidth < 768;
    // Reducimos aún más en móviles para mejor performance
    const numCircles = isMobile ? 8 : 25; 
    const connectionDistance = isMobile ? 100 : 150; // Menor distancia de conexión en móviles

    // Cache de colores para evitar recálculos constantes
    const colorCache = {};
    const getColorCacheKey = (x, y, z, time) => `${Math.round(x/10)}_${Math.round(y/10)}_${Math.round(z*10)}_${Math.round(time/10)}`;

    // Utility functions for creative effects - optimized with caching
    const getIridescentColor = (x, y, z, time) => {
      const cacheKey = getColorCacheKey(x, y, z, time);
      if (colorCache[cacheKey]) return colorCache[cacheKey];
      
      const baseHue = 215;
      const hue = (
        baseHue + 
        (Math.sin(time * 0.004) * 20) + 
        (Math.cos(x * 0.005) * 15) +
        (Math.sin(y * 0.006) * 10) +
        (Math.sin(time * 0.008 + x * 0.01) * 8)
      ) % 360;
      const saturation = 45 + Math.sin(z * Math.PI + time * 0.008) * 12;
      const lightness = 78 + Math.cos(time * 0.003) * 8;
      
      const color = `hsl(${hue}, ${saturation}%, ${lightness}%)`;
      colorCache[cacheKey] = color;
      
      // Limitar el tamaño del cache
      const cacheKeys = Object.keys(colorCache);
      if (cacheKeys.length > 200) {
        delete colorCache[cacheKeys[0]];
      }
      
      return color;
    };

    const getSecondaryIridescence = (x, y, z, time) => {
      const cacheKey = `s_${getColorCacheKey(x, y, z, time)}`;
      if (colorCache[cacheKey]) return colorCache[cacheKey];
      
      const hue = (
        215 + 
        (Math.cos(time * 0.006) * 25) + 
        (Math.sin(x * 0.005) * 20) +
        (Math.cos(y * 0.007) * 15)
      ) % 360;
      const saturation = 50 + Math.cos(z * Math.PI + time * 0.012) * 12;
      const lightness = 75 + Math.sin(time * 0.004) * 10;
      
      const color = `hsl(${hue}, ${saturation}%, ${lightness}%)`;
      colorCache[cacheKey] = color;
      return color;
    };

    class Circle {
      constructor(x, y, radius, dx, dy, dz, depth) {
        this.x = x;
        this.y = y;
        this.z = dz;
        this.radius = radius;
        this.dx = dx;
        this.dy = dy;
        this.dz = dz;
        this.depth = depth;
        this.baseColor = "hsl(215, 35%, 65%)";
        this.time = Math.random() * 1000;
        this.pulsePhase = Math.random() * Math.PI * 2;
        this.rotationAngle = Math.random() * Math.PI * 2;
        this.rotationSpeed = (Math.random() - 0.5) * 0.002;
        this.uniqueOffset = Math.random() * Math.PI * 2;
        
        // Eliminamos el rastro de partículas para mejorar rendimiento
        this.particleTrail = [];
        this.maxTrailLength = isMobile ? 0 : 3; // No trails en móvil
      }

      draw() {
        const scale = (this.z + 1.2) / 2.2;
        // Reducimos la complejidad en móviles
        const pulsingEffect = isMobile ? 0.06 : 0.12;
        const pulsingRadius = this.radius * (1 + Math.sin(this.time * 0.04 + this.uniqueOffset) * pulsingEffect) * scale;
        
        if (pulsingRadius <= 0) return;
        
        const x1 = this.x + Math.cos(this.rotationAngle) * pulsingRadius * 0.25;
        const y1 = this.y + Math.sin(this.rotationAngle) * pulsingRadius * 0.25;

        // Actualizar rastro de partículas (solo en desktop)
        if (!isMobile && this.maxTrailLength > 0) {
          this.particleTrail.unshift({ x: this.x, y: this.y });
          if (this.particleTrail.length > this.maxTrailLength) {
            this.particleTrail.pop();
          }

          // Dibujar rastro
          this.particleTrail.forEach((pos, index) => {
            const alpha = (1 - index / this.maxTrailLength) * 0.2;
            ctx.beginPath();
            ctx.arc(pos.x, pos.y, pulsingRadius * (1 - index / this.maxTrailLength), 0, Math.PI * 2);
            ctx.fillStyle = `rgba(255, 255, 255, ${alpha})`;
            ctx.fill();
          });
        }

        // Dibujar círculo principal
        ctx.beginPath();
        ctx.arc(this.x, this.y, pulsingRadius, 0, Math.PI * 2);

        // Solo usamos gradientes en desktop
        if (isMobile) {
          // Para móviles usar colores simples
          ctx.fillStyle = this.baseColor;
        } else {
          const gradient = ctx.createRadialGradient(
            x1, y1, pulsingRadius * 0.15,
            this.x, this.y, pulsingRadius
          );
          
          const iridescentColor = getIridescentColor(this.x, this.y, this.z, this.time);
          const secondaryColor = getSecondaryIridescence(this.x + 25, this.y + 25, -this.z, this.time * 0.8);
          const tertiaryColor = getIridescentColor(this.x - 15, this.y - 15, this.z, this.time * 1.1);
          
          gradient.addColorStop(0, `rgba(255, 255, 255, ${0.95 * scale})`);
          gradient.addColorStop(0.35, iridescentColor);
          gradient.addColorStop(0.65, secondaryColor);
          gradient.addColorStop(0.85, tertiaryColor);
          gradient.addColorStop(1, this.baseColor);
          
          ctx.fillStyle = gradient;
        }
        
        // Solo aplicamos sombras en desktop
        if (!isMobile) {
          const glowIntensity = Math.abs(Math.sin(this.time * 0.008 + this.uniqueOffset) * 15);
          ctx.shadowColor = getIridescentColor(this.x, this.y, this.z, this.time);
          ctx.shadowBlur = glowIntensity * scale;
        }
        
        ctx.fill();
        
        // Resetear sombras después de dibujar
        ctx.shadowBlur = 0;

        // Omitir efectos de onda en móviles
        if (!isMobile) {
          // Efecto de ondas de energía
          const waveRadius = pulsingRadius * (1.5 + Math.sin(this.time * 0.02) * 0.2);
          ctx.beginPath();
          ctx.arc(this.x, this.y, waveRadius, 0, Math.PI * 2);
          ctx.strokeStyle = `rgba(255, 255, 255, ${0.1 * scale})`;
          ctx.lineWidth = 0.5;
          ctx.stroke();
        }

        // Conexiones simplificadas en móviles
        if (isMobile) return;
        
        // En desktop, mantener conexiones con efecto de energía pero optimizadas
        const circles = circlesRef.current;
        for (let i = 0; i < circles.length; i++) {
          const otherCircle = circles[i];
          if (otherCircle === this) continue;
          
          const dx = this.x - otherCircle.x;
          const dy = this.y - otherCircle.y;
          const distance = Math.sqrt(dx * dx + dy * dy);
          const maxDistance = 120 * scale;
          
          if (distance < maxDistance && distance > 0) {
            ctx.beginPath();
            const gradient = ctx.createLinearGradient(
              this.x, this.y, otherCircle.x, otherCircle.y
            );
            gradient.addColorStop(0, `rgba(255, 255, 255, ${0.1 * scale})`);
            gradient.addColorStop(0.5, `rgba(255, 255, 255, ${0.05 * scale})`);
            gradient.addColorStop(1, `rgba(255, 255, 255, ${0.1 * scale})`);
            
            ctx.strokeStyle = gradient;
            ctx.globalAlpha = (1 - distance / maxDistance) * 0.08 * scale;
            ctx.lineWidth = (1 - distance / maxDistance) * 0.5;
            
            // Simplificamos la línea de energía en vez de usar ondas
            ctx.moveTo(this.x, this.y);
            ctx.lineTo(otherCircle.x, otherCircle.y);
            ctx.stroke();
          }
        }
        
        ctx.globalAlpha = 1;
      }

      update(mouse) {
        const time = this.time * 0.001;
        const wobble = Math.sin(time + this.uniqueOffset) * 0.25;
        
        if (
          this.x + this.radius * ((this.z + 1) / 2) > canvas.width ||
          this.x - this.radius * ((this.z + 1) / 2) < 0
        ) {
          this.dx = -this.dx * (1 + wobble);
        }
        if (
          this.y + this.radius * ((this.z + 1) / 2) > canvas.height ||
          this.y - this.radius * ((this.z + 1) / 2) < 0
        ) {
          this.dy = -this.dy * (1 + wobble);
        }

        const dist = Math.sqrt(
          Math.pow(this.x - mouse.x, 2) + Math.pow(this.y - mouse.y, 2)
        );
        
        // Interacción con el mouse menos intensiva
        if (dist < 180) {
          const force = isMobile ? 0.03 : 0.06;  // Menos fuerza en móviles
          const angle = Math.atan2(mouse.y - this.y, mouse.x - this.x);
          const scale = (this.z + 1) / 2;
          
          // Interacción suave y atractiva
          const attractionForce = Math.sin(time * 2.2) * 0.3 + 0.7;
          this.dx += Math.cos(angle) * force * scale * attractionForce;
          this.dy += Math.sin(angle) * force * scale * attractionForce;
        }

        this.x += this.dx * ((this.z + 1) / 2) * (1 + Math.sin(time * 3.2) * 0.12);
        this.y += this.dy * ((this.z + 1) / 2) * (1 + Math.cos(time * 3.2) * 0.12);
        
        // Reducir la velocidad de cambio en Z en móviles
        const zSpeed = isMobile ? 0.5 : 0.8;
        this.z += this.dz * zSpeed;

        if (this.z > 1 || this.z < -1) {
          this.dz = -this.dz;
        }

        this.rotationAngle += this.rotationSpeed * ((this.z + 1) / 2);
        
        // Incremento más lento del tiempo en móviles
        this.time += isMobile ? 0.6 : 0.9;
        this.draw();
      }
    }

    class Particle {
      constructor(x, y, radius, color) {
        this.x = x;
        this.y = y;
        this.radius = radius;
        this.color = color;
        this.velocity = {
          x: (Math.random() - 0.5) * 2,
          y: (Math.random() - 0.5) * 2,
        };
        this.alpha = 1;
      }

      draw() {
        ctx.save();
        ctx.globalAlpha = this.alpha;
        ctx.beginPath();
        ctx.arc(this.x, this.y, this.radius, 0, Math.PI * 2, false);
        ctx.fillStyle = this.color;
        ctx.fill();
        ctx.restore();
      }

      update() {
        this.x += this.velocity.x;
        this.y += this.velocity.y;
        this.alpha -= 0.01;
      }
    }

    function init() {
      // Limpiar arrays existentes
      circlesRef.current = [];
      particlesRef.current = [];
      
      for (let i = 0; i < numCircles; i++) {
        const radius = Math.random() * 6 + 1;
        const x = Math.random() * (canvas.width - radius * 2) + radius;
        const y = Math.random() * (canvas.height - radius * 2) + radius;
        
        // Movimiento más lento, especialmente en móviles
        const speedFactor = isMobile ? 0.1 : 0.2;
        const dx = (Math.random() - 0.5) * speedFactor;
        const dy = (Math.random() - 0.5) * speedFactor;
        const dz = (Math.random() - 0.5) * 0.008;
        const depth = Math.random() * 0.5 + 0.5;
        
        circlesRef.current.push(new Circle(x, y, radius, dx, dy, dz, depth));
      }
    }

    function animate() {
      if (!canvasRef.current) return;
      
      animationFrameRef.current = requestAnimationFrame(animate);
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      // Usar los círculos del ref
      const circles = circlesRef.current;
      const particles = particlesRef.current;
      const mouse = mouseRef.current;

      for (let i = 0; i < circles.length; i++) {
        circles[i].update(mouse);
      }

      // Actualizar y limpiar partículas
      for (let i = particles.length - 1; i >= 0; i--) {
        particles[i].update();
        if (particles[i].alpha <= 0) {
          particles.splice(i, 1);
        }
      }

      // Solo hacemos estas conexiones en modo desktop
      if (!isMobile) {
        connectCircles();
        connectCirclesToMouse();
      }
    }

    function connectCircles() {
      const circles = circlesRef.current;
      
      // Reducir la complejidad con un enfoque cuadrático optimizado
      for (let i = 0; i < circles.length; i++) {
        for (let j = i + 1; j < circles.length; j++) {
          const dist = Math.sqrt(
            Math.pow(circles[i].x - circles[j].x, 2) +
            Math.pow(circles[i].y - circles[j].y, 2)
          );
          if (dist < connectionDistance) {
            ctx.beginPath();
            ctx.moveTo(circles[i].x, circles[i].y);
            
            // Curva simple en vez de orgánica para mejorar rendimiento
            ctx.lineTo(circles[j].x, circles[j].y);
            
            const alpha = (1 - dist / connectionDistance) * 0.2;
            ctx.strokeStyle = `rgba(200, 220, 240, ${alpha})`;
            ctx.lineWidth = 0.5;
            ctx.stroke();
          }
        }
      }
    }

    function connectCirclesToMouse() {
      const circles = circlesRef.current;
      const mouse = mouseRef.current;
      
      for (let i = 0; i < circles.length; i++) {
        const dist = Math.sqrt(
          Math.pow(circles[i].x - mouse.x, 2) +
          Math.pow(circles[i].y - mouse.y, 2)
        );
        if (dist < connectionDistance) {
          ctx.beginPath();
          ctx.moveTo(circles[i].x, circles[i].y);
          
          // Conexión directa para mejorar rendimiento
          ctx.lineTo(mouse.x, mouse.y);
          
          const alpha = (1 - dist / connectionDistance) * 0.2;
          ctx.strokeStyle = `rgba(215, 235, 255, ${alpha})`;
          ctx.lineWidth = 0.5;
          ctx.stroke();
        }
      }
    }

    // Inicializamos el mouse con la posición central
    mouseRef.current = {
      x: canvas.width / 2,
      y: canvas.height / 2,
    };

    // Throttle para eventos de mouse/touch
    const throttleMouseEvents = (callback, delay) => {
      let lastCall = 0;
      return function(...args) {
        const now = Date.now();
        if (now - lastCall < delay) return;
        lastCall = now;
        callback(...args);
      };
    };

    // Evento de mouse optimizado
    const handleMouseMove = throttleMouseEvents((event) => {
      mouseRef.current.x = event.clientX;
      mouseRef.current.y = event.clientY;
      
      // En desktop creamos partículas, en móvil no
      if (!isMobile) {
        createParticles(mouseRef.current.x, mouseRef.current.y);
      }
    }, 50); // 50ms throttle

    // Evento de touch optimizado
    const handleTouchMove = throttleMouseEvents((event) => {
      mouseRef.current.x = event.touches[0].clientX;
      mouseRef.current.y = event.touches[0].clientY;
      
      // No creamos partículas en evento touch para optimizar
    }, 50);

    // Función optimizada para resize
    const handleResize = () => {
      // Cancelar cualquier timeout pendiente
      if (resizeTimeoutRef.current) {
        clearTimeout(resizeTimeoutRef.current);
      }
      
      // Debounce para el resize
      resizeTimeoutRef.current = setTimeout(() => {
        if (!canvasRef.current) return;
        
        canvas.width = window.innerWidth;
        canvas.height = window.innerHeight;
        
        // Reinicializar después del resize
        init();
      }, 150);
    };

    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("touchmove", handleTouchMove, { passive: allowScroll });
    window.addEventListener("resize", handleResize);

    function createParticles(x, y) {
      // En móvil no creamos partículas
      if (isMobile) return;
      
      // Menos partículas
      const particleCount = 3;
      const particles = particlesRef.current;
      
      for (let i = 0; i < particleCount; i++) {
        particles.push(
          new Particle(
            x,
            y,
            Math.random() * 2 + 1, // Partículas más pequeñas
            `rgba(255, 255, 255, ${Math.random() * 0.4 + 0.4})`
          )
        );
      }
      
      // Limitar el número máximo de partículas
      const maxParticles = isMobile ? 20 : 50;
      if (particles.length > maxParticles) {
        particles.splice(0, particles.length - maxParticles);
      }
    }

    init();
    animate();

    // Cleanup function
    return () => {
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
      if (resizeTimeoutRef.current) {
        clearTimeout(resizeTimeoutRef.current);
      }
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("touchmove", handleTouchMove);
      window.removeEventListener("resize", handleResize);
    };
  }, [allowScroll, opacity]);

  return (
    <div
      style={{
        position: "absolute",
        width: "100%",
        height: "100%",
        opacity: opacity,
      }}
    >
      <canvas
        ref={canvasRef}
        style={{ position: "absolute", top: 0, left: 0, zIndex: -1 }}
      />
    </div>
  );
};

export default React.memo(InteractiveAnimation);