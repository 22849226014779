import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL } from '../config/api';
import keycloak from '../keycloak';

export const fetchUserAds = createAsyncThunk(
  'userAds/fetchUserAds',
  async (_, { rejectWithValue }) => {
    try {
      // Verificar si el usuario está autenticado - esta es una página protegida
      if (!keycloak.authenticated) {
        //console.log("Usuario no autenticado, redirigiendo al login de Keycloak para ver mis anuncios");
        keycloak.login();
        return rejectWithValue('No autenticado');
      }
      
      // Verificar si el token está expirado
      if (keycloak.isTokenExpired()) {
        try {
          // Intentar actualizar el token
          const refreshed = await keycloak.updateToken(30);
          if (refreshed) {
            //console.log("Token actualizado exitosamente en userAdsSlice");
          } else {
            //console.log("Token aún válido en userAdsSlice");
          }
        } catch (error) {
          //  console.error("Error al actualizar el token en userAdsSlice, redirigiendo al login");
          keycloak.login();
          return rejectWithValue('Token expirado');
        }
      }
      
      // Configurar los headers con el token de autenticación de Keycloak
      const headers = {};
      
      if (keycloak.token) {
        headers['Authorization'] = `Bearer ${keycloak.token}`;
        //console.log('Token enviado en la petición de anuncios:', keycloak.token);
      } else {
        //console.warn('Token no disponible para obtener anuncios, redirigiendo al login');
        keycloak.login();
        return rejectWithValue('Token no disponible');
      }
      
      const response = await axios.get(`${BASE_URL}/anunciante/sep`, { headers });
      return response.data;
    } catch (error) {
      // Si el error es 401 o 403, redirigir al login
      if (error.response && (error.response.status === 401 || error.response.status === 403)) {
        //console.error('Error de autenticación:', error.response.status);
        keycloak.login();
      }
      
      //console.error('Error fetching user ads:', error);
      return rejectWithValue(error.response?.data || 'Error al cargar los anuncios');
    }
  }
);

const initialState = {
  ads: [],
  status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
  error: null
};

const userAdsSlice = createSlice({
  name: 'userAds',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserAds.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchUserAds.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.ads = action.payload;
        state.error = null;
      })
      .addCase(fetchUserAds.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

export default userAdsSlice.reducer;
