// src/components/FormStep3.js
import React, { useCallback, memo } from "react";
import { Form } from "react-bootstrap";
import "./FormStep3.css";
import FormError from "./common/FormError";

const FormStep3 = ({
  formData = {},
  onChange,
  onPresupuestoChange,
  errors = {},
  setFormErrors,
}) => {
  const monthsInputRef = React.useRef(null);

  const handleCurrencyClick = useCallback(
    (moneda) => {
      onPresupuestoChange("moneda", moneda);
      // Limpiar error cuando se selecciona una moneda
      setFormErrors((prev) => {
        const newErrors = { ...prev };
        delete newErrors["presupuesto.moneda"];
        return newErrors;
      });
    },
    [onPresupuestoChange, setFormErrors]
  );

  const handlePaymentTypeClick = useCallback(
    (tipo) => {
      onChange("periodicidadPago", tipo);
      // Limpiar error cuando se selecciona un tipo de pago
      setFormErrors((prev) => {
        const newErrors = { ...prev };
        delete newErrors.periodicidadPago;
        return newErrors;
      });

      if (tipo === "mensual") {
        onChange("cantidadMeses", null);
        setTimeout(() => {
          if (monthsInputRef.current) {
            monthsInputRef.current.focus();
            monthsInputRef.current.select();
          }
        }, 100);
      }
    },
    [onChange, setFormErrors]
  );

  const handleMonthsChange = useCallback(
    (e) => {
      const value =
        e.target.value === "" ? null : parseInt(e.target.value) || 1;
      onChange("cantidadMeses", value);
      // Limpiar error cuando se ingresa un valor válido
      if (value >= 1 && value <= 12) {
        setFormErrors((prev) => {
          const newErrors = { ...prev };
          delete newErrors.cantidadMeses;
          return newErrors;
        });
      }
    },
    [onChange, setFormErrors]
  );

  const handleInputChange = useCallback(
    (e) => {
      const value = e.target.value.replace(/[^0-9]/g, "");
      onPresupuestoChange("monto", value);
      // Limpiar error cuando se ingresa un valor válido
      if (value > 0) {
        setFormErrors((prev) => {
          const newErrors = { ...prev };
          delete newErrors["presupuesto.monto"];
          return newErrors;
        });
      }
    },
    [onPresupuestoChange, setFormErrors]
  );

  return (
    <div className="step-container">
      <div className="form-section mb-4">
        <h2 className="section-title text-center mb-3">
          Presupuesto disponible
        </h2>

        <div className="currency-selector">
          <FormError error={errors["presupuesto.moneda"]} />
          <div className="currency-options">
            <div
              className={`currency-option ${
                formData.presupuesto && formData.presupuesto.moneda === "$"
                  ? "active"
                  : ""
              } ${errors["presupuesto.moneda"] ? "is-invalid" : ""}`}
              onClick={() => handleCurrencyClick("$")}
            >
              <Form.Check
                type="radio"
                id="peso"
                label="$"
                name="moneda"
                checked={
                  formData.presupuesto && formData.presupuesto.moneda === "$"
                }
                onChange={() => {}}
              />
            </div>
            <div
              className={`currency-option ${
                formData.presupuesto && formData.presupuesto.moneda === "USD"
                  ? "active"
                  : ""
              } ${errors["presupuesto.moneda"] ? "is-invalid" : ""}`}
              onClick={() => handleCurrencyClick("USD")}
            >
              <Form.Check
                type="radio"
                id="usd"
                label="USD"
                name="moneda"
                checked={
                  formData.presupuesto && formData.presupuesto.moneda === "USD"
                }
                onChange={() => {}}
              />
            </div>
          </div>
        </div>

        <div
          className="amount-input-container"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
            gap: "8px",
          }}
        >
          <FormError error={errors["presupuesto.monto"]} />
          <div
            className={`amount-wrapper ${
              errors["presupuesto.monto"] ? "is-invalid" : ""
            }`}
            style={{
              display: "flex",
              alignItems: "center",
              maxWidth: "300px",
              width: "100%",
              border: errors["presupuesto.monto"]
                ? "1px solid #dc3545"
                : "1px solid #ced4da",
              borderRadius: "4px",
              padding: "0.5rem 0.6rem",
              background: "#fff",
            }}
          >
            <Form.Control
              type="text"
              value={(formData.presupuesto && formData.presupuesto.monto) || ""}
              onChange={handleInputChange}
              placeholder="0"
              className={errors["presupuesto.monto"] ? "is-invalid" : ""}
              style={{
                border: "none",
                boxShadow: "none",
                padding: "0",
                width: "100%",
                height: "auto",
                WebkitAppearance: "none",
                MozAppearance: "textfield",
              }}
            />
            <span
              style={{
                fontSize: "0.85rem",
                color: "#718096",
                fontWeight: "500",
                marginLeft: "8px",
                whiteSpace: "nowrap",
              }}
            >
              {(formData.presupuesto && formData.presupuesto.moneda) === "$"
                ? "Pesos"
                : "Dólares"}
            </span>
          </div>
        </div>
      </div>

      <div className="form-section">
        <h2 className="section-title text-center mb-3">
          Periodicidad de la publicidad
        </h2>

        <FormError error={errors.periodicidadPago} />
        <div className="payment-options">
          <div
            className={`payment-option ${
              formData.periodicidadPago === "simple" ? "active" : ""
            } ${errors.periodicidadPago ? "is-invalid" : ""}`}
            onClick={() => handlePaymentTypeClick("simple")}
            style={{
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Form.Check
              type="radio"
              id="simple"
              label="Por única vez"
              name="periodicidad"
              checked={formData.periodicidadPago === "simple"}
              onChange={() => {}}
            />
            <small
              className="option-description"
              style={{ textAlign: "center" }}
            >
              La publicidad se emite por única vez, en una ocasión o evento específico (ej. Criolla)
            </small>
          </div>

          <div
            className={`payment-option ${
              formData.periodicidadPago === "mensual" ? "active" : ""
            } ${errors.periodicidadPago ? "is-invalid" : ""}`}
            onClick={() => handlePaymentTypeClick("mensual")}
            style={{
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Form.Check
              type="radio"
              id="mensual"
              label="Mensual"
              name="periodicidad"
              checked={formData.periodicidadPago === "mensual"}
              onChange={() => {}}
            />
            <small
              className="option-description"
              style={{ textAlign: "center" }}
            >
              La publicidad se emite el mes o meses seleccionados. De
              emitirse por más de un mes, se renueva automáticamente
            </small>
          </div>
        </div>

        {formData.periodicidadPago === "mensual" && (
          <div className="months-input-section">
            <Form.Group>
              <Form.Label>Mes o meses</Form.Label>
              <FormError error={errors.cantidadMeses} />
              <div className="months-input-wrapper">
                <Form.Control
                  ref={monthsInputRef}
                  type="number"
                  value={formData.cantidadMeses || ""}
                  onChange={handleMonthsChange}
                  min="1"
                  max="12"
                  className={errors.cantidadMeses ? "is-invalid" : ""}
                  style={{
                    border: errors.cantidadMeses
                      ? "1px solid #dc3545"
                      : "1px solid #ced4da",
                    borderRadius: "4px",
                    padding: "0.5rem",
                    width: "100%",
                  }}
                />
              </div>
            </Form.Group>

            {(formData.presupuesto && formData.presupuesto.monto) > 0 && (
              <div
                className="payment-summary mt-3 p-3"
                style={{
                  background: "#f8f9fa",
                  borderRadius: "8px",
                  border: "1px solid #dee2e6",
                }}
              >
                <h5 className="text-center mb-3">Resumen</h5>
                <div className="d-flex justify-content-between mb-2">
                  <span>Se pagará por cada mes:</span>
                  <strong>
                    {formData.presupuesto && formData.presupuesto.moneda}{" "}
                    {formData.presupuesto && formData.presupuesto.monto}
                  </strong>
                </div>
                <div className="d-flex justify-content-between mb-2">
                  <span>Cantidad de meses:</span>
                  <strong>{formData.cantidadMeses}</strong>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(FormStep3);
