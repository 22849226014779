import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import keycloak from '../keycloak';
import { formatDate } from '../utils/formats';
import PageHeader from '../components/PageHeader';
import { fetchUserAds } from '../redux/userAdsSlice';
import ActivitySummary from '../components/ActivitySummary';

const PageContainer = styled.div`
  min-height: 100vh;
  background: #f5f7fa;
`;

const ContentContainer = styled.div`
  max-width: 1200px;
  margin: 140px auto 0;
  padding: 2rem;
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 2rem;
  
  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
  }
`;

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

const SideContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  
  @media (max-width: 1024px) {
    order: -1;
  }
`;

const ProfileSection = styled.div`
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  transition: transform 0.2s;
  
  &:hover {
    transform: translateY(-2px);
  }
`;

const SectionTitle = styled.h2`
  font-size: 1.2rem;
  color: #0b3357;
  margin-bottom: 1.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 2px solid #f0f2f5;
`;

const FormGroup = styled.div`
  margin-bottom: 1.5rem;
  
  &:last-child {
    margin-bottom: 0;
  }
`;

const Label = styled.label`
  display: block;
  margin-bottom: 0.5rem;
  color: #0b3357;
  font-weight: 500;
`;

const Input = styled.input`
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #e1e4e8;
  border-radius: 8px;
  font-size: 1rem;
  transition: all 0.2s;

  &:focus {
    outline: none;
    border-color: #0b3357;
    box-shadow: 0 0 0 2px rgba(11, 51, 87, 0.1);
  }

  &:disabled {
    background-color: #f5f7fa;
    cursor: not-allowed;
  }
`;

const Button = styled.button`
  background-color: #0b3357;
  color: white;
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.2s;
  border: none;
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;

  &:hover {
    background-color: #1557a0;
    transform: translateY(-1px);
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

const StatsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 1rem;
`;

const StatCard = styled.div`
  background: #f8f9fa;
  padding: 1.5rem;
  border-radius: 8px;
  text-align: center;
  border: 1px solid #e1e4e8;
  transition: transform 0.2s;

  &:hover {
    transform: translateY(-2px);
    border-color: #0b3357;
  }
`;

const StatValue = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
  color: #0b3357;
  margin-bottom: 0.5rem;
`;

const StatLabel = styled.div`
  color: #666;
  font-size: 0.9rem;
`;

const LastLoginInfo = styled.div`
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px solid #e1e4e8;
  color: #666;
  font-size: 0.9rem;
  text-align: center;
`;

const Profile = () => {
  const dispatch = useDispatch();
  const { ads, status } = useSelector(state => state.userAds);
  console.log(ads);
  const [userData, setUserData] = useState({
    name: '',
    email: '',
    phone: '',
    company: ''
  });
  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    if (keycloak.tokenParsed) {
      setUserData({
        name: keycloak.tokenParsed.name || keycloak.tokenParsed.preferred_username || '',
        email: keycloak.tokenParsed.email || '',
        phone: keycloak.tokenParsed.phone || '',
        company: keycloak.tokenParsed.company || ''
      });
      dispatch(fetchUserAds());
    }
  }, [dispatch]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSave = async () => {
    setIsSaving(true);
    // Aquí iría la lógica para guardar los cambios en el backend
    setTimeout(() => {
      setIsSaving(false);
      setIsEditing(false);
    }, 1000);
  };

  return (
    <PageContainer>
      <PageHeader title="Mi Perfil" />
      <ContentContainer>
        <MainContent>
          <ProfileSection>
            <SectionTitle>Información Personal</SectionTitle>
            <FormGroup>
              <Label>Nombre completo</Label>
              <Input
                type="text"
                name="name"
                value={userData.name}
                onChange={handleInputChange}
                disabled={!isEditing}
                placeholder="Tu nombre completo"
              />
            </FormGroup>
            <FormGroup>
              <Label>Correo electrónico</Label>
              <Input
                type="email"
                name="email"
                value={userData.email}
                onChange={handleInputChange}
                disabled={!isEditing}
                placeholder="tu@email.com"
              />
            </FormGroup>
            <FormGroup>
              <Label>Teléfono</Label>
              <Input
                type="tel"
                name="phone"
                value={userData.phone}
                onChange={handleInputChange}
                disabled={!isEditing}
                placeholder="Tu número de teléfono"
              />
            </FormGroup>
            <FormGroup>
              <Label>Empresa</Label>
              <Input
                type="text"
                name="company"
                value={userData.company}
                onChange={handleInputChange}
                disabled={!isEditing}
                placeholder="Nombre de tu empresa"
              />
            </FormGroup>
            <Button
              onClick={isEditing ? handleSave : () => setIsEditing(true)}
              disabled={isSaving}
            >
              {isEditing ? (isSaving ? 'Guardando...' : 'Guardar cambios') : 'Editar perfil'}
            </Button>
          </ProfileSection>
{/* 
          <ProfileSection>
            <SectionTitle>Preferencias de Notificación</SectionTitle>
            <FormGroup>
              <Label>
                <Input
                  type="checkbox"
                  style={{ width: 'auto', marginRight: '0.5rem' }}
                />
                Recibir notificaciones por email
              </Label>
            </FormGroup>
            <FormGroup>
              <Label>
                <Input
                  type="checkbox"
                  style={{ width: 'auto', marginRight: '0.5rem' }}
                />
                Recibir actualizaciones de anuncios
              </Label>
            </FormGroup>
          </ProfileSection> */}
        </MainContent>

        <SideContent>
          <ActivitySummary />

          <ProfileSection>
            <SectionTitle>Seguridad</SectionTitle>
            <Button
              onClick={() => window.open(keycloak.createAccountUrl(), '_blank')}
              style={{ width: '100%' }}
            >
              Cambiar contraseña
            </Button>
          </ProfileSection>
        </SideContent>
      </ContentContainer>
    </PageContainer>
  );
};

export default Profile;
